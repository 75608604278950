import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// material
import { alpha, styled } from "@mui/material/styles";
import { Paper, Box, Typography, CircularProgress } from "@mui/material";
// utils
import { fData } from "src/utils/formatNumber";
//
import { UploadIllustration } from "src/assets";
import { useTranslation } from "react-i18next";
import { ApiUrl } from "src/services/apiUrls";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  state: PropTypes.object,
};

export default function UploadSingleFile({
  error,
  file,
  sx,
  onDrop,
  isEdit,
  setImageLoading,
  loading,
  ...other
}) {
  const { t } = useTranslation("common");
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    onDrop,
    ...other,
  });
  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          padding: "8px 0",
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ p: 2, ml: { md: 2 } }}>
          {!loading ? (
            <UploadIllustration sx={{ width: 180 }} />
          ) : (
            <CircularProgress />
          )}
        </Box>
        {(!loading && file && file?.type === "application/pdf") ||
        (isEdit && file?.slice(file.length - 3) === "pdf") ? (
          <Box
            component="img"
            alt="file preview"
            src={"/icons/pdf.png"}
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: "contain",
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "calc(100% - 20px)",
              backgroundColor: "background.paper",
            }}
          />
        ) : (
          file &&
          !loading && (
            <Box
              component="img"
              alt="file preview"
              src={
                isEdit
                  ? `${ApiUrl.IMAGE_BASE_URL + file}`
                  : !file.preview
                  ? file?.url
                  : file.preview
              }
              sx={{
                top: 8,
                borderRadius: 1,
                objectFit: "contain",
                position: "absolute",
                width: "calc(100% - 16px)",
                height: "calc(100% - 16px)",
                backgroundColor: "background.paper",
              }}
            />
          )
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 &&
        (setImageLoading(false), (<ShowRejectionItems />))}
    </Box>
  );
}
