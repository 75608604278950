// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }) {
  const theme = useTheme();

  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_22_3)">
          <path
            d="M100.65 92.4762C100.291 92.5038 99.9423 92.6038 99.6297 92.7691C99.3171 92.9343 99.0479 93.1609 98.8411 93.4328C98.6343 93.7047 98.4949 94.0153 98.4328 94.3427C98.3706 94.6702 98.3872 95.0065 98.4814 95.328L91.2266 99.8512L95.4453 101.465L101.688 96.9225C102.263 96.7432 102.746 96.3755 103.045 95.8891C103.344 95.4027 103.439 94.8313 103.311 94.2833C103.183 93.7352 102.841 93.2485 102.351 92.9155C101.861 92.5825 101.256 92.4262 100.65 92.4762L100.65 92.4762Z"
            fill="#FFB8B8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.3413 65.2097C64.7336 36.6042 103.53 25.6074 139.432 26.7139C176.076 27.8434 211.269 43.6621 235.312 71.1266C258.958 98.1382 268.601 134.502 264.934 170.077C261.442 203.955 244.789 236.693 216.29 255.672C190.575 272.797 157.861 265.578 127.104 261.864C99.4139 258.521 68.7984 257.06 51.0339 235.721C33.6847 214.881 42.1785 185.38 40.9608 158.388C39.505 126.121 23.9466 91.144 43.3413 65.2097Z"
            fill="url(#paint0_linear_22_3)"
            fillOpacity="0.2"
          />
          <path
            d="M99.9433 99.5421L91.0841 107.536C89.8702 108.625 88.2886 109.277 86.6143 109.379L75.8936 110.034C75.5005 110.06 75.1057 110.011 74.7326 109.89C74.3595 109.77 74.0155 109.58 73.7211 109.332C73.3137 108.994 73.0154 108.555 72.8604 108.065C72.7054 107.575 72.7 107.055 72.8448 106.563C72.9897 106.071 73.2789 105.629 73.6793 105.286C74.0796 104.943 74.5749 104.714 75.1082 104.624L86.0913 102.702L96.2113 96.0367C96.3514 95.9667 96.5086 95.9326 96.6673 95.9376C96.826 95.9427 96.9807 95.9867 97.116 96.0654L99.7708 98.2211C99.9315 98.3092 100.059 98.4424 100.136 98.6021C100.212 98.7618 100.234 98.9398 100.198 99.1114C100.162 99.2763 100.073 99.4268 99.9433 99.5421Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M201.844 106.478C201.921 105.919 201.817 105.35 201.543 104.85C201.27 104.349 200.842 103.94 200.317 103.679L147.66 77.546C147.325 77.3797 146.959 77.2777 146.583 77.246C146.207 77.2144 145.829 77.2535 145.469 77.3613C145.11 77.4691 144.776 77.6434 144.488 77.8742C144.199 78.1051 143.961 78.3879 143.788 78.7067L143.787 78.7086L122.852 117.175C122.503 117.82 122.436 118.57 122.667 119.263C122.897 119.956 123.406 120.534 124.082 120.87L176.74 147.003C177.417 147.337 178.204 147.403 178.93 147.185C179.656 146.967 180.262 146.484 180.613 145.84L201.547 107.374C201.7 107.094 201.8 106.791 201.844 106.478Z"
            fill="#F2F2F2"
          />
          <path
            d="M168.683 192.184L99.5493 190.473C98.679 190.451 97.8526 190.1 97.2515 189.498C96.6505 188.897 96.3238 188.093 96.3432 187.264L98.5371 97.8315C98.5584 97.0021 98.9241 96.2156 99.5539 95.6443C100.184 95.0731 101.026 94.7638 101.897 94.7844L171.031 96.495C171.901 96.5175 172.727 96.868 173.328 97.4697C173.929 98.0714 174.256 98.8751 174.237 99.7046L172.043 189.137C172.022 189.966 171.656 190.752 171.026 191.324C170.396 191.895 169.554 192.204 168.683 192.184Z"
            fill="white"
          />
          <path
            d="M119.68 121.907C122.285 121.972 124.445 120.1 124.503 117.727C124.561 115.353 122.496 113.377 119.891 113.312C117.285 113.248 115.126 115.12 115.068 117.493C115.009 119.867 117.074 121.843 119.68 121.907Z"
            fill="#E6E6E6"
          />
          <path
            d="M112.43 129.182C113.076 129.544 113.754 129.854 114.456 130.109L114.58 130.152C115.994 130.652 117.489 130.926 119.001 130.961C119.305 130.969 119.598 130.966 119.872 130.954C120.539 130.932 121.203 130.864 121.858 130.749C122.218 130.686 122.577 130.608 122.924 130.518C123.008 130.497 123.086 130.477 123.171 130.454C124.589 130.066 125.926 129.463 127.13 128.669C127.039 128.5 126.939 128.335 126.83 128.176C126.243 127.297 125.429 126.568 124.463 126.054C123.497 125.54 122.409 125.257 121.297 125.23L118.86 125.17C117.574 125.139 116.307 125.451 115.21 126.069C114.113 126.687 113.231 127.586 112.671 128.658C112.581 128.829 112.501 129.004 112.43 129.182Z"
            fill="#E6E6E6"
          />
          <path
            d="M120.858 130.617C120.669 130.623 120.487 130.667 120.329 130.745C121.121 130.727 121.91 130.663 122.687 130.554L120.858 130.617Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M120.344 105.954C117.405 105.881 114.526 106.725 112.154 108.353C109.781 109.981 108.049 112.303 107.224 114.959C106.4 117.614 106.53 120.455 107.594 123.039C108.659 125.624 110.598 127.807 113.111 129.252C113.778 129.637 114.48 129.966 115.209 130.234L115.333 130.278C116.739 130.781 118.225 131.057 119.727 131.093C120.018 131.1 120.308 131.098 120.595 131.085C121.257 131.063 121.917 130.993 122.568 130.878C122.932 130.812 123.29 130.733 123.628 130.644C123.711 130.623 123.788 130.602 123.873 130.579C126.916 129.728 129.533 127.88 131.254 125.369C132.975 122.858 133.687 119.848 133.262 116.881C132.837 113.913 131.302 111.183 128.934 109.18C126.566 107.177 123.52 106.033 120.344 105.954L120.344 105.954ZM127.547 128.131C126.375 128.939 125.064 129.551 123.67 129.938C123.595 129.959 123.517 129.98 123.44 130.001C123.118 130.085 122.78 130.159 122.433 130.22C121.817 130.331 121.192 130.397 120.565 130.418C120.294 130.43 120.017 130.432 119.743 130.425C118.321 130.391 116.915 130.13 115.583 129.655L115.467 129.613C114.742 129.348 114.045 129.018 113.386 128.629C111.089 127.28 109.31 125.275 108.31 122.907C107.31 120.539 107.141 117.934 107.829 115.474C108.517 113.014 110.025 110.83 112.131 109.241C114.238 107.653 116.831 106.745 119.532 106.65C122.232 106.554 124.896 107.278 127.132 108.713C129.369 110.149 131.06 112.221 131.957 114.625C132.854 117.029 132.91 119.638 132.116 122.069C131.322 124.5 129.721 126.625 127.547 128.131L127.547 128.131Z"
            fill="#CCCCCC"
          />
          <path
            d="M168.683 192.184L99.5493 190.473C98.679 190.451 97.8526 190.1 97.2515 189.498C96.6505 188.897 96.3238 188.093 96.3432 187.264L98.5371 97.8315C98.5584 97.0021 98.9241 96.2156 99.5539 95.6443C100.184 95.0731 101.026 94.7638 101.897 94.7844L171.031 96.495C171.901 96.5175 172.727 96.868 173.328 97.4697C173.929 98.0714 174.256 98.8751 174.237 99.7046L172.043 189.137C172.022 189.966 171.656 190.752 171.026 191.324C170.396 191.895 169.554 192.204 168.683 192.184ZM101.888 95.1525C101.12 95.1343 100.376 95.4072 99.8205 95.9112C99.2648 96.4152 98.9421 97.1093 98.9233 97.8411L96.7294 187.273C96.7123 188.005 97.0006 188.714 97.5309 189.245C98.0612 189.776 98.7904 190.085 99.5583 190.105L168.692 191.816C169.46 191.834 170.204 191.561 170.759 191.057C171.315 190.553 171.638 189.859 171.657 189.127L173.851 99.695C173.868 98.9632 173.579 98.254 173.049 97.7231C172.519 97.1922 171.79 96.8829 171.022 96.8631L101.888 95.1525Z"
            fill="#CCCCCC"
          />
          <path
            d="M157.149 119.393L137.301 118.902C136.885 118.892 136.489 118.726 136.201 118.442C135.914 118.159 135.757 117.779 135.767 117.388C135.777 116.997 135.951 116.626 136.252 116.357C136.554 116.088 136.957 115.942 137.373 115.952L157.221 116.443C157.638 116.454 158.033 116.619 158.321 116.903C158.609 117.187 158.765 117.566 158.755 117.957C158.746 118.348 158.571 118.719 158.27 118.988C157.969 119.258 157.565 119.403 157.149 119.393Z"
            fill="#E6E6E6"
          />
          <path
            d="M163.378 124.527L137.172 123.879C136.757 123.869 136.364 123.703 136.077 123.42C135.791 123.136 135.635 122.757 135.645 122.365C135.655 121.974 135.828 121.603 136.128 121.334C136.428 121.065 136.83 120.919 137.244 120.929L163.45 121.578C163.865 121.588 164.259 121.753 164.545 122.037C164.832 122.321 164.987 122.7 164.977 123.091C164.968 123.482 164.794 123.853 164.494 124.123C164.194 124.392 163.793 124.538 163.378 124.527Z"
            fill="#E6E6E6"
          />
          <path
            d="M156.548 143.46L107.686 142.251C107.274 142.24 106.884 142.075 106.599 141.791C106.315 141.508 106.161 141.129 106.17 140.737C106.18 140.346 106.353 139.975 106.65 139.706C106.948 139.437 107.347 139.291 107.758 139.301L156.621 140.51C157.032 140.52 157.423 140.685 157.708 140.969C157.992 141.253 158.146 141.632 158.137 142.023C158.127 142.414 157.954 142.785 157.657 143.055C157.359 143.324 156.96 143.47 156.548 143.46Z"
            fill="#E6E6E6"
          />
          <path
            d="M162.772 148.594L107.562 147.228C107.151 147.218 106.761 147.052 106.477 146.769C106.193 146.485 106.039 146.106 106.048 145.715C106.058 145.323 106.23 144.952 106.528 144.683C106.825 144.414 107.224 144.268 107.635 144.278L162.844 145.644C163.256 145.654 163.646 145.82 163.93 146.103C164.214 146.387 164.368 146.766 164.359 147.157C164.349 147.549 164.177 147.92 163.879 148.189C163.581 148.458 163.183 148.604 162.772 148.594Z"
            fill="#E6E6E6"
          />
          <path
            d="M156.146 159.876L107.283 158.667C106.872 158.657 106.481 158.492 106.196 158.208C105.912 157.924 105.758 157.545 105.767 157.154C105.777 156.763 105.95 156.392 106.248 156.123C106.546 155.853 106.944 155.708 107.356 155.718L156.218 156.927C156.63 156.937 157.021 157.102 157.305 157.386C157.589 157.67 157.744 158.049 157.734 158.44C157.724 158.831 157.552 159.202 157.254 159.472C156.956 159.741 156.557 159.886 156.146 159.876Z"
            fill="#E6E6E6"
          />
          <path
            d="M162.369 165.011L107.16 163.644C106.749 163.634 106.358 163.469 106.074 163.185C105.79 162.902 105.636 162.523 105.645 162.131C105.655 161.74 105.828 161.369 106.125 161.1C106.423 160.83 106.821 160.685 107.232 160.695L162.442 162.061C162.853 162.071 163.243 162.236 163.527 162.52C163.811 162.804 163.966 163.183 163.956 163.574C163.946 163.965 163.774 164.336 163.476 164.606C163.179 164.875 162.781 165.021 162.369 165.011Z"
            fill="#E6E6E6"
          />
          <path
            d="M155.743 176.293L106.881 175.084C106.469 175.074 106.078 174.909 105.794 174.625C105.509 174.341 105.355 173.962 105.365 173.571C105.374 173.18 105.547 172.809 105.845 172.539C106.143 172.27 106.541 172.124 106.953 172.135L155.815 173.344C156.227 173.354 156.618 173.519 156.902 173.803C157.186 174.086 157.341 174.466 157.331 174.857C157.322 175.248 157.149 175.619 156.851 175.888C156.553 176.158 156.155 176.303 155.743 176.293Z"
            fill="#E6E6E6"
          />
          <path
            d="M161.967 181.427L106.757 180.061C106.346 180.051 105.955 179.886 105.671 179.602C105.387 179.318 105.233 178.939 105.243 178.548C105.252 178.157 105.425 177.786 105.722 177.517C106.02 177.247 106.418 177.102 106.829 177.112L162.039 178.478C162.45 178.488 162.84 178.654 163.123 178.938C163.407 179.221 163.561 179.6 163.551 179.991C163.542 180.382 163.37 180.752 163.072 181.022C162.775 181.291 162.378 181.437 161.967 181.427Z"
            fill="#E6E6E6"
          />
          <path
            d="M198.858 131.981C198.935 131.422 198.83 130.854 198.557 130.353C198.284 129.852 197.856 129.443 197.331 129.182L144.674 103.049C144.339 102.883 143.973 102.781 143.597 102.749C143.221 102.718 142.843 102.757 142.483 102.865C142.124 102.972 141.79 103.147 141.502 103.378C141.213 103.608 140.975 103.891 140.801 104.21L140.8 104.212L119.866 142.679C119.517 143.323 119.45 144.074 119.681 144.766C119.911 145.459 120.42 146.037 121.096 146.374L173.754 172.506C174.43 172.841 175.218 172.906 175.944 172.689C176.67 172.471 177.275 171.987 177.627 171.343L198.561 132.877C198.714 132.597 198.814 132.294 198.858 131.981Z"
            fill="white"
          />
          <path
            d="M198.858 131.981C198.935 131.422 198.83 130.854 198.557 130.353C198.284 129.852 197.856 129.443 197.331 129.182L144.674 103.049C144.339 102.883 143.973 102.781 143.597 102.749C143.221 102.718 142.843 102.757 142.483 102.865C142.124 102.972 141.79 103.147 141.502 103.378C141.213 103.608 140.975 103.891 140.801 104.21L140.8 104.212L119.866 142.679C119.517 143.323 119.45 144.074 119.681 144.766C119.911 145.459 120.42 146.037 121.096 146.374L173.754 172.506C174.43 172.841 175.218 172.906 175.944 172.689C176.67 172.471 177.275 171.987 177.627 171.343L198.561 132.877C198.714 132.597 198.814 132.294 198.858 131.981ZM177.327 171.195C177.017 171.762 176.483 172.189 175.842 172.381C175.201 172.574 174.506 172.516 173.909 172.22L121.251 146.088C120.655 145.791 120.206 145.281 120.002 144.67C119.799 144.059 119.858 143.396 120.166 142.828L141.101 104.361C141.41 103.793 141.944 103.366 142.585 103.174C143.226 102.982 143.921 103.04 144.518 103.335L197.176 129.468C197.772 129.765 198.221 130.275 198.425 130.886C198.628 131.497 198.569 132.159 198.261 132.728L177.327 171.195Z"
            fill={theme.palette.grey[500]}
          />
          <path
            d="M193.039 133.61L178.402 160.469C178.304 160.648 178.135 160.783 177.933 160.844C177.73 160.905 177.511 160.886 177.322 160.793L177.322 160.793L129.355 137.02C129.321 137.004 129.288 136.984 129.257 136.962C129.101 136.855 128.99 136.698 128.945 136.52C128.9 136.342 128.924 136.154 129.012 135.991L143.649 109.132C143.698 109.043 143.764 108.964 143.844 108.9C143.925 108.836 144.018 108.787 144.118 108.757C144.218 108.727 144.324 108.716 144.429 108.725C144.533 108.733 144.635 108.762 144.729 108.808L192.696 132.581C192.884 132.674 193.027 132.835 193.091 133.028C193.155 133.221 193.137 133.43 193.039 133.609L193.039 133.609L193.039 133.61Z"
            fill="#E6E6E6"
          />
          <path
            d="M150.553 131.176C154.536 131.274 157.838 128.36 157.928 124.666C158.019 120.972 154.864 117.898 150.881 117.799C146.899 117.701 143.597 120.615 143.506 124.309C143.416 128.003 146.571 131.077 150.553 131.176Z"
            fill={theme.palette.primary.main}
          />
          <path
            d="M160.486 152.473L129.627 137.293C129.588 137.275 129.551 137.253 129.516 137.228L157.201 120.568C157.372 120.463 157.569 120.403 157.772 120.394C157.976 120.385 158.178 120.427 158.359 120.516C158.54 120.605 158.693 120.738 158.802 120.9C158.911 121.063 158.972 121.25 158.98 121.443L159.991 142.259L160.04 143.256L160.486 152.473Z"
            fill={theme.palette.grey[500]}
          />
          <path
            opacity="0.2"
            d="M160.485 152.472L149.639 147.282L158.961 142.749L159.632 142.422L159.968 142.259L160.019 143.255L160.485 152.472Z"
            fill="black"
          />
          <path
            d="M177.406 160.919L150.651 147.788L159.734 143.248L160.388 142.921L172.225 137.004C173.001 136.617 174.088 137.102 174.317 137.886C174.333 137.939 174.344 137.993 174.352 138.048L177.406 160.919Z"
            fill={theme.palette.grey[500]}
          />
          <path
            d="M94.8351 161.251L98.0464 161.33L99.8367 150.672L95.0967 150.555L94.8351 161.251Z"
            fill="#FFB8B8"
          />
          <path
            d="M94.1193 160.328L100.103 160.476L100.103 160.476C100.604 160.488 101.097 160.591 101.555 160.777C102.014 160.963 102.428 161.23 102.774 161.562C103.12 161.894 103.392 162.285 103.573 162.712C103.754 163.139 103.842 163.594 103.83 164.051L103.828 164.164L94.0313 163.922L94.1193 160.328Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M72.3976 158.93L75.5919 159.571L79.8043 148.854L75.0901 147.907L72.3976 158.93Z"
            fill="#FFB8B8"
          />
          <path
            d="M71.8879 157.835L78.0466 159.095L78.0468 159.095C78.5622 159.2 79.0503 159.398 79.4833 159.675C79.9163 159.953 80.2856 160.306 80.5703 160.713C80.8549 161.121 81.0492 161.575 81.1421 162.051C81.235 162.526 81.2248 163.013 81.1119 163.484L81.0839 163.601L71.0004 161.538L71.8879 157.835Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M100.414 124.467C100.319 124.146 100.149 123.847 99.9152 123.591C99.6813 123.336 99.3895 123.131 99.0603 122.99C98.7311 122.849 98.3724 122.775 98.0097 122.775C97.647 122.775 97.2889 122.847 96.9607 122.988L90.7953 117.182L89.8244 121.284L95.828 126.169C96.1243 126.659 96.6062 127.034 97.1823 127.223C97.7585 127.411 98.389 127.399 98.9542 127.189C99.5195 126.979 99.9803 126.586 100.249 126.085C100.518 125.583 100.577 125.007 100.414 124.467Z"
            fill="#FFB8B8"
          />
          <path
            d="M78.5308 94.8245C81.8611 94.9069 84.6219 92.4862 84.6972 89.4178C84.7725 86.3493 82.1337 83.795 78.8033 83.7126C75.473 83.6302 72.7122 86.0509 72.6369 89.1194C72.5617 92.1878 75.2004 94.7421 78.5308 94.8245Z"
            fill="#FFB8B8"
          />
          <path
            d="M77.2584 94.8781C77.5738 95.0152 78.284 92.6791 78.0033 92.2014C77.5858 91.491 77.6113 91.4967 77.3341 91.0266C77.0569 90.5566 77.0079 89.9214 77.3916 89.5234C77.7752 89.1254 78.6386 89.2008 78.7966 89.7157C78.7107 88.743 79.7654 87.9889 80.7965 87.7952C81.8276 87.6015 82.9062 87.7643 83.9424 87.5948C85.1449 87.3982 86.4131 86.4994 85.9654 85.2681C85.8884 85.063 85.7703 84.8732 85.6173 84.7085C85.0796 84.1201 84.3138 83.8555 83.565 83.5996C82.0071 83.067 80.4065 82.5272 78.7483 82.5407C77.3815 82.5731 76.0561 82.974 74.9343 83.6943C73.8125 84.4147 72.943 85.4232 72.4321 86.5966C72.3106 86.8903 72.2147 87.1926 72.1452 87.5006C71.507 90.3197 73.2142 93.1581 76.0719 94.3083L77.2584 94.8781Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M75.014 125.362C74.8656 125.273 74.748 125.145 74.6758 124.993C74.6035 124.842 74.5798 124.674 74.6074 124.511L72.2486 112.026L69.7827 102.428L69.7827 102.428C69.5119 101.376 69.6286 100.27 70.1137 99.2912C70.5987 98.3123 71.4233 97.5188 72.4521 97.0408L74.2759 95.4954L78.1308 97.1708L79.9376 98.9873C81.2497 99.6284 82.4057 100.52 83.3313 101.606C84.2569 102.692 84.9315 103.947 85.312 105.291L88.045 114.923L94.6627 124.69C94.7732 124.852 94.822 125.045 94.8012 125.237C94.7804 125.429 94.6911 125.608 94.548 125.746C93.5709 126.688 75.014 125.362 75.014 125.362Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M92.9467 124.923L82.9369 118.08C81.5728 117.141 80.6018 115.786 80.1926 114.25L77.573 104.416C77.4753 104.056 77.4558 103.681 77.5156 103.314C77.5754 102.947 77.7132 102.596 77.921 102.28C78.2033 101.844 78.6118 101.491 79.0995 101.263C79.5871 101.035 80.1341 100.94 80.6777 100.99C81.2213 101.04 81.7395 101.232 82.1725 101.544C82.6056 101.857 82.9361 102.277 83.1261 102.756L87.127 112.615L95.9647 120.857C96.0635 120.975 96.1278 121.115 96.151 121.263C96.1743 121.411 96.1558 121.563 96.0973 121.702L94.3062 124.536C94.2424 124.7 94.1251 124.841 93.9708 124.94C93.8166 125.038 93.633 125.089 93.4459 125.085C93.2659 125.08 93.0915 125.024 92.9467 124.923Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M71.1399 157.242C71.0602 157.129 71.0106 156.999 70.9952 156.864C70.9798 156.728 70.9992 156.592 71.0516 156.465L76.1072 140.583L76.8364 124.916L92.559 124.289L92.6353 124.286L97.1649 132.49C98.5832 135.055 99.4209 137.869 99.6247 140.752L100.787 156.904C100.806 157.123 100.732 157.341 100.582 157.509C100.432 157.677 100.218 157.783 99.9857 157.804L95.2421 158.112C95.0134 158.132 94.785 158.067 94.6056 157.931C94.4261 157.795 94.3099 157.599 94.2817 157.384L92.7613 140.962L86.1164 129.765L83.5102 141.316L78.3077 157.228C78.2409 157.387 78.1246 157.522 77.9741 157.616C77.8236 157.711 77.6462 157.759 77.4653 157.756L71.8561 157.62C71.7132 157.617 71.5732 157.581 71.4484 157.515C71.3235 157.449 71.2175 157.356 71.1399 157.242Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M188.581 212.794L71.7529 209.903C69.6816 209.85 67.7151 209.014 66.2848 207.578C64.8544 206.142 64.0771 204.224 64.1233 202.245L65.6272 140.942C65.6781 138.963 66.5485 137.086 68.0475 135.723C69.5465 134.36 71.5516 133.622 73.623 133.671L87.947 134.025C89.8906 134.072 91.7477 134.81 93.1541 136.096L108.428 147.078C109.419 147.988 110.729 148.51 112.101 148.543L190.835 150.491C191.87 150.517 192.891 150.739 193.837 151.145C194.783 151.55 195.635 152.131 196.345 152.854C197.054 153.577 197.607 154.427 197.97 155.355C198.333 156.282 198.5 157.27 198.461 158.259L196.573 205.632C196.486 207.589 195.602 209.434 194.108 210.773C192.614 212.111 190.63 212.837 188.581 212.794Z"
            fill="white"
          />
          <path
            d="M187.657 209.15L72.5175 206.301C71.5179 206.275 70.5689 205.871 69.8786 205.179C69.1884 204.486 68.8132 203.561 68.8355 202.606L70.364 140.301C70.3885 139.346 70.8086 138.441 71.532 137.783C72.2553 137.126 73.223 136.77 74.2226 136.793C75.1067 136.816 75.9461 137.173 76.5566 137.786C77.1672 138.398 77.499 139.216 77.4792 140.061L76.046 198.484C76.0251 199.379 76.3767 200.246 77.0236 200.895C77.6705 201.544 78.5599 201.922 79.4967 201.947L187.379 204.616C187.915 204.623 188.434 204.802 188.852 205.124C189.27 205.446 189.563 205.891 189.686 206.39C189.824 206.864 189.793 207.368 189.597 207.822C189.401 208.276 189.051 208.654 188.603 208.895C188.319 209.062 187.991 209.151 187.657 209.15Z"
            fill="#F2F2F2"
          />
          <path
            d="M129.089 179.533L128.475 178.936C128.348 178.819 128.275 178.661 128.273 178.496C128.271 178.331 128.34 178.172 128.465 178.055C128.475 178.045 128.485 178.036 128.496 178.027L134.121 173.063C134.183 173.005 134.256 172.958 134.337 172.927C134.419 172.895 134.506 172.879 134.594 172.878C134.682 172.878 134.77 172.893 134.851 172.924C134.933 172.955 135.008 173.001 135.071 173.058C135.082 173.068 135.092 173.078 135.101 173.088L140.487 178.331C140.55 178.39 140.599 178.461 140.633 178.538C140.667 178.615 140.685 178.697 140.684 178.78C140.684 178.863 140.666 178.945 140.631 179.021C140.597 179.097 140.546 179.166 140.483 179.225L140.465 179.24L139.824 179.805C139.761 179.863 139.685 179.909 139.602 179.94C139.52 179.971 139.431 179.986 139.341 179.986C139.252 179.985 139.163 179.967 139.081 179.935C138.998 179.902 138.923 179.855 138.86 179.796L138.844 179.78L135.664 176.516L135.486 184.062C135.485 184.144 135.466 184.225 135.431 184.301C135.396 184.376 135.345 184.444 135.281 184.501C135.218 184.558 135.142 184.603 135.06 184.633C134.977 184.663 134.889 184.677 134.8 184.676L134.795 184.676L133.888 184.652C133.708 184.648 133.536 184.577 133.411 184.455C133.286 184.333 133.218 184.171 133.222 184.003L133.399 176.467L130.069 179.563C129.94 179.68 129.766 179.744 129.585 179.741C129.405 179.739 129.232 179.67 129.105 179.549L129.089 179.533Z"
            fill={theme.palette.primary.main}
          />
          <path
            d="M134.789 166.787C137.163 166.848 139.467 167.596 141.41 168.935C143.354 170.275 144.849 172.146 145.707 174.312C146.565 176.478 146.747 178.842 146.231 181.105C145.714 183.368 144.522 185.429 142.805 187.025C141.088 188.622 138.924 189.684 136.585 190.077C134.247 190.469 131.839 190.175 129.667 189.231C127.495 188.287 125.656 186.735 124.383 184.773C123.109 182.81 122.459 180.525 122.513 178.205C122.589 175.096 123.924 172.147 126.226 170.006C128.527 167.866 131.607 166.708 134.789 166.787ZM134.826 165.243C132.14 165.174 129.496 165.885 127.228 167.286C124.961 168.686 123.171 170.714 122.086 173.113C121.002 175.511 120.67 178.173 121.134 180.76C121.597 183.348 122.835 185.746 124.691 187.651C126.547 189.556 128.937 190.882 131.559 191.462C134.181 192.042 136.918 191.85 139.423 190.909C141.928 189.969 144.089 188.322 145.633 186.178C147.176 184.034 148.033 181.489 148.095 178.864C148.177 175.344 146.826 171.932 144.337 169.378C141.849 166.823 138.427 165.336 134.826 165.243Z"
            fill={theme.palette.grey[500]}
          />
          <path
            d="M188.581 212.794L71.7529 209.903C69.6816 209.85 67.7151 209.014 66.2848 207.578C64.8544 206.142 64.0771 204.224 64.1233 202.245L65.6272 140.942C65.6781 138.963 66.5485 137.086 68.0475 135.723C69.5465 134.36 71.5516 133.622 73.623 133.671L87.947 134.025C89.8906 134.072 91.7477 134.81 93.1541 136.096L108.428 147.078C109.419 147.988 110.729 148.51 112.101 148.543L190.835 150.491C191.87 150.517 192.891 150.739 193.837 151.145C194.783 151.55 195.635 152.131 196.345 152.854C197.054 153.577 197.607 154.427 197.97 155.355C198.333 156.282 198.5 157.27 198.461 158.259L196.573 205.632C196.486 207.589 195.602 209.434 194.108 210.773C192.614 212.111 190.63 212.837 188.581 212.794ZM73.6119 134.123C71.6661 134.077 69.7825 134.77 68.3743 136.051C66.9662 137.332 66.1485 139.095 66.1007 140.954L64.5968 202.257C64.5534 204.116 65.2836 205.918 66.6273 207.266C67.9709 208.615 69.8183 209.401 71.764 209.451L188.592 212.342C190.517 212.382 192.381 211.7 193.784 210.443C195.187 209.185 196.018 207.452 196.1 205.613L197.988 158.24C198.025 157.311 197.868 156.384 197.527 155.512C197.185 154.641 196.666 153.842 196 153.163C195.333 152.484 194.532 151.938 193.644 151.557C192.755 151.176 191.796 150.968 190.824 150.944L112.09 148.996C110.605 148.96 109.186 148.396 108.111 147.414L92.8371 136.432C91.5149 135.218 89.7663 134.521 87.9359 134.478L73.6119 134.123Z"
            fill={theme.palette.grey[500]}
          />
          <path
            d="M247.537 218.553L157.185 216.318C157.122 216.316 157.063 216.291 157.019 216.247C156.976 216.204 156.952 216.146 156.954 216.086C156.955 216.026 156.981 215.969 157.027 215.928C157.072 215.886 157.133 215.864 157.196 215.865L247.548 218.101C247.611 218.103 247.67 218.128 247.714 218.171C247.757 218.215 247.781 218.273 247.779 218.333C247.778 218.393 247.751 218.45 247.706 218.491C247.66 218.533 247.6 218.555 247.537 218.553Z"
            fill="#CCCCCC"
          />
          <path
            d="M231.174 128.525C228.968 125.469 224.462 125.218 224.462 125.218C224.462 125.218 220.078 124.671 217.147 129.17C214.415 133.363 217.988 138.747 217.988 138.747C218.718 138.806 221.881 138.902 231.323 138.049L231.797 135.933L232.959 137.902C233.464 137.857 233.988 137.81 234.537 137.762C234.345 135.5 233.296 131.465 231.174 128.525Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M235.369 170.622L235.209 151.196L230.84 152.77L232.488 170.761C232.03 171.06 231.694 171.482 231.53 171.966C231.366 172.45 231.383 172.968 231.578 173.445C231.773 173.921 232.136 174.33 232.612 174.61C233.088 174.89 233.653 175.026 234.222 174.999C234.791 174.971 235.333 174.781 235.768 174.457C236.204 174.133 236.508 173.692 236.637 173.2C236.766 172.708 236.712 172.191 236.484 171.727C236.255 171.262 235.864 170.875 235.369 170.622Z"
            fill="#FFB8B8"
          />
          <path
            d="M230.193 153.594C233.014 153.907 235.07 152.922 236.495 150.867L235.641 146.996C235.672 145.744 235.252 144.514 234.442 143.483C233.631 142.453 232.473 141.676 231.136 141.266L230.635 141.112L230.193 153.594Z"
            fill="#E6E6E6"
          />
          <path
            d="M208.929 147.727C208.892 147.726 208.855 147.721 208.819 147.714L203.597 147.506L203.588 147.391L203.588 147.386L204.256 137.848C204.263 137.751 204.292 137.655 204.341 137.568C204.39 137.481 204.457 137.403 204.54 137.339C204.623 137.275 204.719 137.226 204.824 137.196C204.928 137.165 205.038 137.153 205.148 137.16L209.328 137.429C209.438 137.436 209.546 137.462 209.644 137.506C209.743 137.55 209.831 137.61 209.904 137.684C209.976 137.758 210.032 137.844 210.067 137.936C210.102 138.029 210.117 138.127 210.109 138.224L209.475 147.284C209.467 147.406 209.405 147.52 209.303 147.603C209.201 147.685 209.067 147.73 208.929 147.727L208.929 147.727Z"
            fill="#FF3030"
          />
          <path
            d="M208.375 147.72C208.361 147.719 208.348 147.719 208.334 147.718L204.237 147.454C204.004 147.439 203.787 147.342 203.633 147.186C203.48 147.029 203.402 146.826 203.417 146.619L204.069 137.912C204.084 137.715 204.187 137.532 204.354 137.403C204.521 137.274 204.739 137.21 204.961 137.224L209.142 137.493C209.363 137.508 209.57 137.599 209.716 137.749C209.863 137.898 209.937 138.092 209.922 138.289L209.27 146.996C209.255 147.196 209.154 147.383 208.987 147.518C208.82 147.653 208.601 147.725 208.375 147.72Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M206.725 140.078C207.161 140.089 207.521 139.86 207.528 139.566C207.535 139.273 207.187 139.026 206.751 139.015C206.315 139.005 205.955 139.234 205.948 139.527C205.941 139.821 206.289 140.067 206.725 140.078Z"
            fill="#FF3030"
          />
          <path
            d="M211.155 150.157L211.069 153.662L207.532 148.236C207.803 147.831 207.924 147.355 207.878 146.88C207.832 146.405 207.62 145.955 207.275 145.599C206.93 145.243 206.47 144.998 205.964 144.903C205.458 144.807 204.932 144.864 204.465 145.067C203.999 145.27 203.616 145.607 203.375 146.028C203.133 146.448 203.046 146.93 203.126 147.402C203.206 147.873 203.449 148.309 203.818 148.644C204.188 148.978 204.664 149.194 205.176 149.259L210.097 157.773C210.354 158.217 210.757 158.574 211.248 158.793C211.739 159.011 212.292 159.08 212.826 158.988C213.361 158.897 213.849 158.65 214.22 158.284C214.591 157.917 214.825 157.451 214.888 156.952L215.676 150.721L211.155 150.157Z"
            fill="#FFB8B8"
          />
          <path
            d="M216.092 152.994C213.411 153.156 211.514 152.134 210.262 150.135L211.249 146.533C211.277 145.354 211.732 144.216 212.545 143.285C213.358 142.354 214.489 141.677 215.772 141.353L216.252 141.232L216.092 152.994Z"
            fill="#E6E6E6"
          />
          <path
            d="M220.074 215.935L217.102 215.442L217.799 205.298L222.185 206.025L220.074 215.935Z"
            fill="#FFB6B6"
          />
          <path
            d="M220.812 217.907L211.546 217.686L211.549 217.58C211.569 216.709 211.969 215.883 212.659 215.284C213.35 214.685 214.275 214.361 215.232 214.383L215.232 214.383L220.891 214.518L220.812 217.907Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M233.266 210.344L230.665 211.458L224.641 202.859L228.481 201.214L233.266 210.344Z"
            fill="#FFB6B6"
          />
          <path
            d="M235.03 212.352L226.638 215.945L226.587 215.848C226.174 215.061 226.122 214.158 226.443 213.338C226.763 212.519 227.43 211.85 228.296 211.479L228.296 211.479L233.422 209.285L235.03 212.352Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M223.874 138.015C226.648 138.083 228.947 136.084 229.009 133.549C229.072 131.014 226.873 128.903 224.099 128.834C221.325 128.765 219.025 130.765 218.963 133.3C218.901 135.835 221.099 137.946 223.874 138.015Z"
            fill="#FFB8B8"
          />
          <path
            d="M230.264 128.95L225.802 126.657L219.544 127.395L218.125 132.617C224.473 133.619 228.869 134.212 230.384 132.821L230.264 128.95Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M215.364 161.936C215.364 161.936 211.445 169.002 215.332 179.696L226.117 207.29L231.667 204.399L226.374 192.503L231.267 178.223C232.393 174.988 232.799 171.569 232.462 168.176C232.196 165.637 231.5 163.045 229.88 161.706C226.068 158.557 215.364 161.936 215.364 161.936Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M220.957 190.288L216.207 209.869L221.865 210.984L225.858 194.033L220.957 190.288Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M227.432 140.347L221.071 140.269L215.475 141.326L215.294 162.145C215.294 162.145 225.294 165.052 229.804 161.486L233.198 142.08C231.583 141.176 229.631 140.631 227.432 140.347Z"
            fill="#E4E4E4"
          />
          <path
            d="M227.085 128.799C228.909 128.844 230.42 127.564 230.46 125.94C230.499 124.317 229.053 122.964 227.229 122.918C225.405 122.873 223.895 124.153 223.855 125.777C223.815 127.401 225.261 128.754 227.085 128.799Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M223.948 125.1C223.958 124.665 224.08 124.239 224.304 123.851C224.529 123.463 224.85 123.124 225.245 122.858C225.64 122.591 226.1 122.405 226.59 122.311C227.08 122.218 227.588 122.22 228.079 122.317C227.648 122.129 227.177 122.022 226.696 122.002C226.216 121.983 225.737 122.053 225.291 122.206C224.845 122.359 224.442 122.593 224.11 122.892C223.778 123.191 223.523 123.548 223.363 123.94C223.202 124.331 223.14 124.748 223.18 125.164C223.221 125.579 223.362 125.983 223.596 126.348C223.829 126.714 224.149 127.033 224.535 127.284C224.921 127.535 225.363 127.713 225.833 127.806C225.256 127.554 224.772 127.166 224.438 126.687C224.105 126.208 223.934 125.658 223.948 125.1Z"
            fill={theme.palette.primary.darker}
          />
          <path
            d="M200.905 105.242C207.291 105.249 212.461 100.197 212.454 93.9574C212.447 87.718 207.265 82.6541 200.879 82.647C194.494 82.6398 189.323 87.6921 189.33 93.9315C189.338 100.171 194.52 105.235 200.905 105.242Z"
            fill={theme.palette.primary.main}
          />
          <path
            d="M141.788 230.179C141.295 232.271 139.857 234.154 137.735 235.485C135.613 236.817 132.948 237.507 130.224 237.432C127.501 237.357 124.9 236.521 122.895 235.076C120.89 233.631 119.614 231.673 119.299 229.559C119.52 229.559 119.74 229.579 119.955 229.618C121.061 229.82 121.962 230.424 122.908 230.918C123.855 231.411 125.02 231.815 126.103 231.546C127.136 231.292 127.773 230.511 128.43 229.84C129.088 229.168 130.036 228.5 131.106 228.634C132.619 228.822 133.261 230.452 134.759 230.696C136.42 230.968 137.754 229.377 139.448 229.408C140.138 229.421 140.767 229.705 141.361 229.981C141.504 230.047 141.645 230.114 141.788 230.179Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M130.722 237.765C127.837 237.764 125.051 236.936 122.895 235.44C120.739 233.943 119.365 231.883 119.034 229.652C118.984 229.304 118.958 228.954 118.957 228.604C118.955 226.795 119.642 225.026 120.932 223.523C122.222 222.02 124.056 220.85 126.204 220.159C128.351 219.469 130.714 219.291 132.995 219.646C135.276 220.002 137.371 220.875 139.017 222.157C140.662 223.438 141.784 225.069 142.239 226.845C142.695 228.62 142.464 230.459 141.576 232.13C140.688 233.8 139.183 235.228 137.251 236.231C135.319 237.234 133.047 237.767 130.722 237.765ZM130.701 219.775C127.689 219.774 124.802 220.703 122.673 222.358C120.545 224.014 119.35 226.26 119.349 228.604C119.35 228.943 119.374 229.282 119.423 229.618C119.677 231.313 120.554 232.916 121.95 234.234C123.345 235.551 125.2 236.528 127.29 237.045C129.381 237.562 131.617 237.598 133.73 237.149C135.843 236.699 137.742 235.783 139.199 234.512C140.656 233.24 141.609 231.667 141.941 229.981C142.274 228.296 141.973 226.57 141.075 225.013C140.177 223.455 138.72 222.132 136.879 221.202C135.038 220.273 132.893 219.777 130.701 219.775Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M138.542 222.743C140.517 222.746 142.117 221.504 142.115 219.97C142.114 218.437 140.511 217.192 138.535 217.19C136.56 217.187 134.959 218.429 134.961 219.962C134.963 221.496 136.566 222.741 138.542 222.743Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M146.804 218.758C146.703 218.525 146.508 218.318 146.242 218.162C145.976 218.006 145.652 217.908 145.309 217.88C145.301 217.879 145.293 217.879 145.285 217.88C145.276 217.878 145.266 217.876 145.256 217.875C144.645 217.82 144.024 217.856 143.435 217.982C142.846 218.109 142.301 218.322 141.838 218.607L141.828 218.614C141.601 218.761 141.325 218.866 141.025 218.917C140.725 218.968 140.411 218.964 140.114 218.905C139.95 218.873 139.78 218.859 139.611 218.865C139.249 218.882 138.903 218.976 138.621 219.137C138.339 219.297 138.134 219.515 138.034 219.761C138.023 219.791 138.026 219.823 138.043 219.851C138.059 219.88 138.089 219.904 138.126 219.92C138.234 219.985 138.362 220.032 138.5 220.055C138.795 220.1 139.093 220.14 139.392 220.178C139.975 220.25 140.561 220.309 141.15 220.354C141.539 220.384 141.929 220.407 142.32 220.425C143.128 220.462 143.939 220.471 144.748 220.454C145.142 220.465 145.535 220.417 145.9 220.312C146.167 220.22 146.395 220.08 146.562 219.906C146.719 219.739 146.821 219.55 146.863 219.351C146.905 219.153 146.885 218.951 146.804 218.758Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M139.388 222.807C139.381 222.688 139.354 222.57 139.307 222.456C139.198 222.194 138.987 221.96 138.701 221.785C138.415 221.609 138.066 221.499 137.696 221.468C137.688 221.467 137.68 221.467 137.672 221.468C137.661 221.465 137.65 221.463 137.639 221.462C136.926 221.397 136.203 221.451 135.524 221.62C134.94 221.76 134.403 221.989 133.948 222.293C133.704 222.46 133.406 222.577 133.083 222.635C132.76 222.692 132.422 222.688 132.101 222.622C131.925 222.586 131.742 222.57 131.559 222.577C131.169 222.596 130.797 222.702 130.493 222.883C130.189 223.063 129.969 223.309 129.861 223.585C129.85 223.619 129.853 223.655 129.871 223.687C129.889 223.719 129.92 223.746 129.961 223.765C130.077 223.838 130.214 223.89 130.362 223.916C130.682 223.966 131.002 224.012 131.325 224.054C131.952 224.136 132.583 224.202 133.217 224.253C134.504 224.356 135.8 224.394 137.093 224.366C137.518 224.378 137.941 224.323 138.335 224.205C138.621 224.101 138.867 223.944 139.045 223.748C139.297 223.471 139.418 223.14 139.388 222.807Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M138.542 222.743C140.517 222.746 142.117 221.504 142.115 219.97C142.114 218.437 140.511 217.192 138.535 217.19C136.56 217.187 134.959 218.429 134.961 219.962C134.963 221.496 136.566 222.741 138.542 222.743Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M146.804 218.758C146.703 218.525 146.508 218.318 146.242 218.162C145.976 218.006 145.652 217.908 145.309 217.88C145.301 217.879 145.293 217.879 145.285 217.88C145.276 217.878 145.266 217.876 145.256 217.875C144.645 217.82 144.024 217.856 143.435 217.982C142.846 218.109 142.301 218.322 141.838 218.607L141.828 218.614C141.601 218.761 141.325 218.866 141.025 218.917C140.725 218.968 140.411 218.964 140.114 218.905C139.95 218.873 139.78 218.859 139.611 218.865C139.249 218.882 138.903 218.976 138.621 219.137C138.339 219.297 138.134 219.515 138.034 219.761C138.023 219.791 138.026 219.823 138.043 219.851C138.059 219.88 138.089 219.904 138.126 219.92C138.234 219.985 138.362 220.032 138.5 220.055C138.795 220.1 139.093 220.14 139.392 220.178C139.975 220.25 140.561 220.309 141.15 220.354C141.539 220.384 141.929 220.407 142.32 220.425C143.128 220.462 143.939 220.471 144.748 220.454C145.142 220.465 145.535 220.417 145.9 220.312C146.167 220.22 146.395 220.08 146.562 219.906C146.719 219.739 146.821 219.55 146.863 219.351C146.905 219.153 146.885 218.951 146.804 218.758Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M139.388 222.807C139.381 222.688 139.354 222.57 139.307 222.456C139.198 222.194 138.987 221.96 138.701 221.785C138.415 221.609 138.066 221.499 137.696 221.468C137.688 221.467 137.68 221.467 137.672 221.468C137.661 221.465 137.65 221.463 137.639 221.462C136.926 221.397 136.203 221.451 135.524 221.62C134.94 221.76 134.403 221.989 133.948 222.293C133.704 222.46 133.406 222.577 133.083 222.635C132.76 222.692 132.422 222.688 132.101 222.622C131.925 222.586 131.742 222.57 131.559 222.577C131.169 222.596 130.797 222.702 130.493 222.883C130.189 223.063 129.969 223.309 129.861 223.585C129.85 223.619 129.853 223.655 129.871 223.687C129.889 223.719 129.92 223.746 129.961 223.765C130.077 223.838 130.214 223.89 130.362 223.916C130.682 223.966 131.002 224.012 131.325 224.054C131.952 224.136 132.583 224.202 133.217 224.253C134.504 224.356 135.8 224.394 137.093 224.366C137.518 224.378 137.941 224.323 138.335 224.205C138.621 224.101 138.867 223.944 139.045 223.748C139.297 223.471 139.418 223.14 139.388 222.807Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M126.905 225.739C126.586 225.816 126.213 225.78 125.89 225.855C125.729 225.893 125.596 225.959 125.516 226.043L124.998 225.692C124.97 225.678 124.932 225.669 124.893 225.669C124.854 225.669 124.816 225.677 124.788 225.692C124.761 225.707 124.746 225.727 124.746 225.748C124.746 225.769 124.761 225.789 124.788 225.804C125.032 225.969 125.275 226.134 125.519 226.299C125.612 226.361 125.811 226.294 125.766 226.222C125.675 226.074 125.989 225.992 126.205 225.968C126.494 225.955 126.779 225.924 127.054 225.876C127.228 225.834 127.077 225.697 126.905 225.739Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M133.721 227.087C133.402 227.203 133.029 227.149 132.706 227.262C132.545 227.318 132.412 227.418 132.332 227.544L131.814 227.018C131.786 226.996 131.748 226.984 131.709 226.984C131.67 226.984 131.632 226.996 131.604 227.018C131.577 227.041 131.562 227.071 131.562 227.102C131.562 227.134 131.577 227.164 131.604 227.187C131.848 227.434 132.091 227.68 132.335 227.927C132.428 228.022 132.627 227.92 132.582 227.812C132.491 227.591 132.805 227.468 133.022 227.432C133.31 227.411 133.595 227.365 133.87 227.293C134.044 227.23 133.893 227.024 133.721 227.087Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M128.944 221.527C128.625 221.643 128.253 221.59 127.93 221.702C127.768 221.759 127.636 221.858 127.555 221.984L127.037 221.459C127.009 221.437 126.971 221.424 126.932 221.424C126.893 221.424 126.855 221.436 126.828 221.459C126.801 221.481 126.786 221.511 126.786 221.543C126.786 221.574 126.801 221.604 126.828 221.627C127.071 221.874 127.315 222.121 127.558 222.368C127.651 222.462 127.85 222.36 127.806 222.252C127.715 222.031 128.028 221.908 128.245 221.872C128.534 221.852 128.818 221.805 129.094 221.733C129.267 221.67 129.116 221.465 128.944 221.527Z"
            fill="#D6D6D6"
            fillOpacity="0.56"
          />
          <path
            d="M197.195 94.2193L196.741 93.8055C196.647 93.725 196.594 93.6156 196.592 93.5014C196.59 93.3871 196.641 93.2774 196.733 93.1962C196.741 93.1895 196.749 93.1831 196.757 93.1768L200.91 89.7444C200.956 89.7042 201.01 89.6723 201.07 89.6504C201.13 89.6285 201.194 89.6171 201.259 89.6169C201.325 89.6167 201.389 89.6275 201.45 89.649C201.51 89.6704 201.565 89.7019 201.612 89.7417C201.62 89.7484 201.627 89.7552 201.635 89.7623L205.615 93.3928C205.661 93.4339 205.698 93.4824 205.723 93.5357C205.748 93.5889 205.761 93.6459 205.761 93.7033C205.761 93.7607 205.747 93.8174 205.722 93.8702C205.696 93.923 205.659 93.9708 205.612 94.0109L205.599 94.0215L205.126 94.4123C205.079 94.4525 205.023 94.4842 204.962 94.5055C204.901 94.5269 204.835 94.5376 204.769 94.537C204.703 94.5364 204.638 94.5244 204.577 94.5019C204.516 94.4793 204.46 94.4466 204.414 94.4056L204.402 94.3944L202.051 92.1346L201.923 97.3556C201.922 97.4126 201.908 97.4688 201.882 97.5209C201.856 97.5731 201.819 97.6203 201.772 97.6597C201.725 97.6991 201.669 97.7301 201.608 97.7507C201.547 97.7714 201.482 97.7814 201.416 97.7802L201.412 97.7802L200.742 97.7636C200.609 97.7603 200.482 97.7111 200.39 97.6268C200.297 97.5425 200.247 97.4301 200.25 97.3142L200.378 92.0996L197.919 94.2403C197.824 94.321 197.695 94.3653 197.562 94.3634C197.428 94.3616 197.301 94.3138 197.207 94.2305L197.195 94.2193Z"
            fill="#6B0600"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_22_3"
            x1="53.1642"
            y1="54.3197"
            x2="226.246"
            y2="248.1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0047FF" />
            <stop offset="1" stopColor="#0038FF" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_22_3">
            <rect width="300" height="300" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
