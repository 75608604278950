import { createContext, useState } from "react";
import { defineAbilitiesForRole } from "./abilityBuilder";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router";
import { AbilityContext } from "./Can";
import NoData from "src/pages/noData";
import UnAuth from "src/pages/unAuth";

const AclGuard = (props) => {
  const auth = useAuth();

  const [ability, setAbility] = useState(undefined);
  const { aclAbilities, children } = props;

  if (
    window.location.pathname === "/404" ||
    window.location.pathname === "/auth/login" ||
    window.location.pathname === "/auth/register" ||
    window.location.pathname === "/"
  ) {
    return <>{children}</>;
  }

  if (auth?.user && auth?.user?.role && !ability) {
    console.log(auth.user);
    let options = auth?.user?.role?.options.map((item) => item.value);
    console.log(options);
    setAbility(
      defineAbilitiesForRole(
        auth?.user?.role?.value,
        options,
        aclAbilities.subject
      )
    );
  }

  if (ability && ability?.can("read", aclAbilities?.subject)) {
    return (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    );
  } else {
    return (
      <>
        <UnAuth />
      </>
    );
  }
};

export default AclGuard;
