// material
import { paramCase } from "change-case";
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Rating,
  Switch,
  Tooltip,
  Link,
} from "@mui/material";
// redux
import { fCurrency } from "src/utils/formatNumber";
import { fDateShort } from "src/utils/formatTime";
// components
import { Label } from "src/components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import * as api from "src/services";
import toast from "react-hot-toast";
const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  minWidth: 50,
  objectFit: "cover",
  background: theme.palette.background.default,
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function DocumentRow({
  isLoading,
  row,
  locationList,
  documentCategory,
  handleClickOpen,

  serialNumber,
}) {
  const queryClient = useQueryClient();
  // const { 0: location } = {
  //   ...locationList?.filter((item) => item?._id === row?.location),
  // };

  const matchedLocation = locationList?.filter((item) =>
    row?.location?.includes(item._id)
  );
  console.log("location", matchedLocation);
  // console.log("location", location);
  console.log("row", row);
  const { 0: document } = {
    ...documentCategory?.filter((item) => item?._id === row?.category),
  };

  console.log("document", documentCategory);
  // console.log("document", document);
  const { mutate } = useMutation(() => api.updateDocumentStatus(row?._id), {
    onSuccess: (data) => {
      toast.success("Status Changed successfully");
      queryClient.invalidateQueries(["add_document"]);
    },
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <TableRow hover key={Math.random()}>
      <TableCell>{serialNumber}</TableCell>
      <TableCell>
        {isLoading ? <Skeleton variant="text" /> : <>{document?.name}</>}
      </TableCell>

      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          matchedLocation
            ?.slice(0, 4)
            .map(
              (item, index) =>
                `${item?.name}${
                  index === matchedLocation?.slice(0, 4).length - 1
                    ? `,`
                    : `...`
                }`
            )
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {isLoading ? <Skeleton variant="text" /> : row?.documents?.length}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Switch
            {...label}
            checked={row?.is_active}
            onChange={() => {
              mutate();
            }}
          />
        )}
      </TableCell>

      <TableCell align="right">
        {isLoading ? (
          <Stack
            direction="row"
            justifyContent="flex-end"
            paddingRight="0px !important"
          >
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton variant="circular" width={34} height={34} />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(`/add-document/${row._id}`)}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
