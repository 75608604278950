import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
// import toast from 'react-hot-toast';
import { Form, FormikProvider, useFormik } from "formik";
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import * as api from "src/services";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "src/redux/slices/settings";

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["setting", "common"]);
  const reduxProfile = useSelector((state) => state?.settings?.user?.userData);

  const { mutate, isLoading: updateLoading } = useMutation(api.updateProfile, {
    onSuccess: (data) => {
      dispatch(setLogin(data.data));
      window.localStorage.removeItem("token");
      window.localStorage.setItem("token", data.data.access_token);
      toast.success(t(`${data.message}`));
    },
  });

  const user = reduxProfile || {};
  const isLoading = user ? false : true;
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string(),
    email: user?.email || "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      id: user._id,
    },

    // validationSchema: UpdateUserSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  // const handleDrop = async (acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   if (file) {
  //     setLoadingUpload(true);
  //     setFieldValue("file", file);
  //     setFieldValue("photoURL", {
  //       ...file,
  //       preview: URL.createObjectURL(file),
  //     });
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("upload_preset", "my-uploads");

  //     const config = {
  //       onUploadProgress: (progressEvent) => {
  //         const { loaded, total } = progressEvent;
  //         const percentage = Math.floor((loaded * 100) / total);
  //         callbackLoading(percentage);
  //       },
  //     };
  //     await axios
  //       .post(
  //         `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
  //         formData,
  //         config
  //       )
  //       .then(({ data }) => {
  //         setFieldValue("avatar", {
  //           _id: data.public_id,
  //           url: data.secure_url,
  //         });
  //       })
  //       .then(() => {
  //         avatarId && avatarMutate({ _id: avatarId });
  //         setLoadingUpload(false);
  //       });
  //   }
  // };
  console.log(values);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              {isLoading ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Skeleton
                        variant="rectangular"
                        height={56}
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Skeleton
                        variant="rectangular"
                        height={56}
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Stack spacing={{ xs: 2, md: 3 }}>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label={t("name")}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      fullWidth
                      disabled
                      label={t("email-address")}
                      {...getFieldProps("email")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                {isLoading ? (
                  <Skeleton variant="rectangular" height={36} width={124} />
                ) : (
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={updateLoading}
                  >
                    {t("save-changes")}
                  </LoadingButton>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
