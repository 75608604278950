import React from "react";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
// components
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Label } from "src/components";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "src/utils/formatTime";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import * as api from "src/services";
const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "cover",
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
export default function OrderRow({
  isLoading,
  row,
  isUser,
  productList,
  serialNumber,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { data: locationData } = useQuery(["locationData"], () =>
    api.getTrueLocation("")
  );
  const matchedLocation = locationData?.data?.filter((item) =>
    row?.products[0]?.location?.includes(item._id)
  );
  console.log(row);
  return (
    <TableRow hover key={Math.random()}>
      <TableCell component="th" scope="row" padding="none">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={50}
            height={50}
            sx={{ borderRadius: 1 }}
          />
        ) : (
          `${serialNumber}`
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <> {row?.products[0]?.name}</>
        )}
      </TableCell>

      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          `${row.currency || "US$"} ${row.total}`
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          matchedLocation
            ?.slice(0, 7)
            .map(
              (item, index) =>
                `${item?.name}${
                  index === matchedLocation?.slice(0, 7).length - 1
                    ? matchedLocation.length <
                      matchedLocation?.slice(0, 7).length - 1
                      ? `...`
                      : ``
                    : `, `
                }`
            )
        )}
      </TableCell>

      {/* <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={row?.is_active ? "success" : "error"}
          >
            {row.is_active ? "active" : "in-active"}
          </Label>
        )}
      </TableCell> */}
      <TableCell>
        {isLoading ? <Skeleton variant="text" /> : row?.order_type}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end">
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
          ) : (
            <Tooltip title="Preview">
              <IconButton onClick={() => navigate(`/orders/${row._id}`)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
