import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
// import toast from 'react-hot-toast';
import { capitalCase } from "change-case";
import toast from "react-hot-toast";
import { Form, Formik, FormikProvider, useFormik } from "formik";
// material
import imageCompression from "browser-image-compression";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Stack,
  TextField,
  Typography,
  Box,
  Select,
  FormControl,
  FormHelperText,
  Grid,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import * as api from "src/services";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UploadMultiFile } from "src/components/upload";
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function CategoryForm({
  currentCategory,
  isInitialized,
  getBoolValues,
}) {
  const [imageLoading, setImageLoading] = useState(false);
  const [multiEdit, setMultiEdit] = useState(true);
  const queryClient = useQueryClient();
  const [count, setcount] = useState(0);
  const navigate = useNavigate();
  const cid = currentCategory?._id;
  const { t } = useTranslation("categories");
  // const { mutate, isLoading } = useMutation(
  //   currentCategory?._id ? "update" : "new",
  //   currentCategory?._id ? api.updateCategory : api.addCategory,
  //   {
  //     retry: false,
  //     onSuccess: (data) => {
  //       toast.success(
  //         currentCategory ? t(`${data.message}`) : t(`${data.message}`)
  //       );
  //       navigate("/categories");
  //       getBoolValues(false);
  //       queryClient.invalidateQueries({ queryKey: ["categories"] });
  //     },
  //     onError: (error) => {
  //       toast.error(t(`common:errors.${error.message}`));
  //     },
  //   }
  // );
  const { mutate: deleteGallery, isLoading: deleteLoading } = useMutation(
    api.singleDeleteFile,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["update-category", cid]);
        toast.success(t(`common:errors.deleted-success`));
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    currentCategory?._id ? api.updateCategory : api.addCategory,
    {
      onSuccess: () => {
        navigate("/categories");
        queryClient.invalidateQueries({ queryKey: ["categories"] });
        toast.success(
          cid
            ? t(`common:errors.product-updated`)
            : t(`common:errors.product-created`)
        );
      },
    }
  );
  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required(t("name-is-required")),
    gallery: Yup.mixed().required(t("image-is-required")),
    is_active: Yup.boolean(),
  });
  // console.log(currentCategory);
  const formik = useFormik({
    initialValues: {
      name: currentCategory?.name || "",
      is_active: currentCategory ? currentCategory?.is_active : false,
      gallery: currentCategory?.gallery || [],
      blob: currentCategory?.blob || [],
    },
    enableReinitialize: true,
    validationSchema: NewProductSchema,
    onSubmit: (values) => {
      console.log(values);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("is_active", values.is_active);
      values.gallery.length > 0
        ? values?.gallery?.map((item) => {
            formData.append("gallery", item);
          })
        : formData.append("gallery", "[]");
      try {
        mutate({
          formData,
          ...(currentCategory && {
            id: currentCategory._id,
          }),
        });
      } catch (error) {
        console.error(error);
      }
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;
  console.log(values.gallery);

  const handleMultiDrop = async (acceptedFiles) => {
    if (acceptedFiles[0]) {
      setImageLoading(true);
      setMultiEdit(false);
      let filesArr = [];
      acceptedFiles?.map(async (item) => {
        const compressedFile = await imageCompression(item, {
          maxSizeMB: 1,
        });
        console.log(compressedFile, "FILECOM");
        const myFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });
        console.log(myFile, "FILEARR");
        if (myFile) {
          filesArr.push(myFile);
          setImageLoading(false);
          setFieldValue("gallery", filesArr);
        }
      });
      const blobs = acceptedFiles.map((file) => {
        return URL.createObjectURL(file);
      });
      setFieldValue("blob", blobs);
    }
  };

  const handleRemoveAll = () => {
    setFieldValue("gallery", []);
  };

  function handleRemove(file, index) {
    // console.log(file, index);
    if (!multiEdit) {
      setFieldValue(
        "blob",
        values.blob.filter((item) => item !== file)
      );
      setFieldValue(
        "gallery",
        values.gallery.filter((item, ind) => ind !== index)
      );
    } else {
      deleteGallery({ id: cid, url: file });
    }
  }
  console.log(values.gallery, "GALLERY");
  return (
    <Box position="relative">
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/* <Card sx={{ p: 3 }}> */}
              <Stack spacing={3}>
                <div>
                  {isInitialized ? (
                    <Skeleton variant="text" width={140} />
                  ) : (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <LabelStyle> {t("category-name")} </LabelStyle>
                      <div>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values?.is_active}
                                setFieldValue={setFieldValue}
                                {...getFieldProps("is_active")}
                              />
                            }
                            label={t("is_active")}
                          />
                        </FormGroup>
                      </div>
                    </Box>
                  )}
                  {isInitialized ? (
                    <Skeleton variant="rectangular" width="100%" height={56} />
                  ) : (
                    <TextField
                      fullWidth
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onBlur={() => setcount(count + 1)}
                    />
                  )}
                </div>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12}>
              <div>
                {isInitialized ? (
                  <Skeleton variant="text" width={150} />
                ) : (
                  <LabelStyle>{t("category-images")}</LabelStyle>
                )}
                {isInitialized ? (
                  <Skeleton variant="rectangular" width="100%" height={225} />
                ) : (
                  <>
                    <UploadMultiFile
                      showPreview
                      isEdit={multiEdit}
                      accept="image/*"
                      files={multiEdit ? values.gallery : values.blob}
                      loading={false}
                      imageLoading={imageLoading}
                      onDrop={handleMultiDrop}
                      onRemove={handleRemove}
                      onRemoveAll={handleRemoveAll}
                      blob={values.blob}
                      error={Boolean(touched.gallery && errors.gallery)}
                    />
                    {touched.gallery && errors.gallery && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.gallery && errors.gallery}
                      </FormHelperText>
                    )}
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div
                style={{
                  position: "-webkit-sticky",
                  position: "sticky",
                  top: 0,
                }}
              >
                <Stack spacing={3}>
                  {isInitialized ? (
                    <Skeleton variant="rectangular" width="100%" height={56} />
                  ) : (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isLoading}
                      sx={{ ml: "auto", mt: 3 }}
                    >
                      {t(cid ? "edit-category" : "create-category")}
                    </LoadingButton>
                  )}
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
