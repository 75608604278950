import { Icon } from "@iconify/react";

const sidebarConfig = [
  {
    title: "Product",
    path: "/products",
    icon: <Icon icon="fluent:building-shop-20-regular" />,
    isSearch: true,
    subject: "product",
    color: "rgb(212,0,0,0.5)",
    selected_color: "rgb(212,0,0,0.2)",
  },
  {
    title: "Add Ons",
    path: "/add_ons",
    icon: <Icon icon="fluent:collections-add-20-filled" />,
    isSearch: true,
    subject: "addons",
    color: "rgb(212,0,0,0.5)",
    selected_color: "rgb(212,0,0,0.2)",
  },
  {
    title: "categories",
    path: "/categories",
    icon: <Icon icon="bx:category" />,
    isSearch: true,
    subject: "categories",
    color: "rgb(212,0,0,0.5)",
    selected_color: "rgb(212,0,0,0.2)",
  },
  {
    title: "City",
    path: "/city",
    icon: <Icon icon="mdi:location" />,
    isSearch: true,
    subject: "locations",
    color: "rgb(212,0,0,0.5)",
    selected_color: "rgb(212,0,0,0.2)",
  },
  {
    title: "Zone",
    path: "/zone",
    icon: <Icon icon="grommet-icons:map-location" />,
    subject: "zone",
    isSearch: true,
    color: "rgb(212,0,0,0.5)",
    selected_color: "rgb(212,0,0,0.2)",
  },
  {
    title: "Jobs",
    path: "/jobs",
    icon: <Icon icon="fluent:building-shop-20-regular" />,
    isSearch: true,
    subject: "jobs",
    color: "rgb(0,201,197,0.5)",
    selected_color: "rgb(0,201,197,0.1)",
  },
  {
    title: "Documents",
    icon: <Icon icon="teenyicons:documents-solid" />,
    subject: "document",
    color: "rgb(0,201,197,0.5)",
    selected_color: "rgb(0,201,197,0.1)",
    children: [
      {
        title: "Document Category",
        path: "/document-category",
        // icon: <Icon icon="arcticons:folder-documents" />,
        subject: "document",
        color: "rgb(0,201,197,0.5)",
        selected_color: "rgb(0,201,197,0.1)",
      },
      {
        title: "Add Document",
        // icon: <Icon icon="arcticons:folder-documents" />,
        path: "/add-document",
        subject: "document",
        color: "rgb(0,201,197,0.5)",
        selected_color: "rgb(0,201,197,0.1)",
      },
    ],
  },
  {
    title: "Sales",
    path: "/dashboard",
    icon: <Icon icon="ant-design:dashboard-outlined" />,
    isSearch: false,
    color: "rgb(0,201,197,0.5)",
    selected_color: "rgb(0,201,197,0.1)",
    subject: "dashboard",
  },
  {
    title: "Orders",
    path: "/orders",
    icon: <Icon icon={"fluent-mdl2:activate-orders"} />,
    subject: "orders",
    color: "rgb(0,201,197,0.5)",
    selected_color: "rgb(0,201,197,0.1)",
  },
  {
    title: "Consultation",
    path: "/consultation",
    icon: <Icon icon={"guidance:meeting-room"} />,
    subject: "consultation",
    color: "rgb(0,201,197,0.5)",
    selected_color: "rgb(0,201,197,0.1)",
  },
  {
    title: "Employees",
    path: "/employees",
    isSearch: true,
    icon: <Icon icon="clarity:employee-group-solid" />,
    subject: "employees",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
  {
    title: "User",
    path: "/user",
    isSearch: true,
    icon: <Icon icon="fa:users" />,
    subject: "user",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
  {
    title: "Role",
    icon: <Icon icon="oui:app-users-roles" />,
    subject: "roles",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",

    children: [
      {
        title: "All Roles",
        path: "/role",
        subject: "roles",
        color: "rgb(249,218,99,0.5)",
        selected_color: "rgb(249,218,99,0.1)",
      },
      {
        title: "Role option",
        path: "/role-options",
        subject: "roles",
        color: "rgb(249,218,99,0.5)",
        selected_color: "rgb(249,218,99,0.1)",
      },
    ],
  },
  {
    title: "settings",
    path: "/settings",
    icon: <Icon icon="bytesize:settings" />,
    subject: "setting",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
  {
    title: "slides",
    path: "/slides",
    icon: <Icon icon="solar:slider-vertical-linear" />,
    subject: "slides",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
  {
    title: "banner",
    path: "/banner",
    icon: <Icon icon={"material-symbols:planner-banner-ad-pt-outline"} />,
    subject: "banner",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
  {
    title: "SEO Settings",
    path: "/seo",
    icon: <Icon icon={"tabler:seo"} />,
    subject: "seo",
    color: "rgb(249,218,99,0.5)",
    selected_color: "rgb(249,218,99,0.1)",
  },
];

export default sidebarConfig;
