import React from "react";
import EditIcon from "@mui/icons-material/Edit";
// material
import {
  Box,
  Card,
  Table,
  TableBody,
  TableContainer,
  Stack,
  TableRow,
  TableCell,
  Fab,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TableHead from "./tableHead";
import NotData from "src/pages/noData";

// ----------------------------------------------------------------------

export default function CustomTable({ ...props }) {
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);
  function handleChangePage(event, newpage) {
    setpg(parseFloat(newpage));
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value));
    setpg(0);
  }
  const {
    type,
    headData,
    data,
    isLoading,
    mobileRow,
    row,
    rows,
    hiddenPagination,
    getBoolValues,
    ...rest
  } = props;
  const Component = row;
  const CardComponent = mobileRow;
  const { outerIndex, values } = rest;
  if (values) {
    if (values.assigned_docs[outerIndex].docs.length > 0) {
      let doc = values.assigned_docs[outerIndex].docs;
      let newData = data.map((item, index) =>
        doc.find((docItem) => docItem._id == item._id) == undefined
          ? item
          : doc[index]
      );
      console.log(newData, "newData");
      values.assigned_docs[outerIndex].docs = newData;
    } else {
      values.assigned_docs[outerIndex].docs = data;
    }
  }
  return (
    <>
      {!isLoading && data?.data?.length === 0 ? (
        <NotData />
      ) : (
        <>
          <Card sx={{ display: { sm: "block", xs: "none" } }}>
            <TableContainer>
              <Table sx={{ minWidth: 800, overflow: "auto" }}>
                <TableHead headData={headData} />
                <TableBody>
                  {(isLoading
                    ? Array.from(new Array(rows || 6))
                    : (
                        (values && values.assigned_docs[outerIndex].docs) ||
                        data?.data ||
                        data
                      )?.slice(pg * rpg, pg * rpg + rpg)
                  )?.map((item, index) => {
                    return (
                      <Component
                        key={Math.random()}
                        row={item}
                        index={index}
                        data={data}
                        serialNumber={
                          pg == 0 ? index + 1 : pg * rpg + index + 1
                        }
                        isLoading={isLoading}
                        {...rest}
                      />
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 25]}
                      count={(data?.data || data)?.length}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Card>

          {/* {mobileRow && (
            <Box sx={{ display: { sm: "none", xs: "block" } }}>
              {(isLoading ? Array.from(new Array(6)) : data.data).map((row) => (
                <CardComponent
                  key={Math.random()}
                  item={row}
                  isLoading={isLoading}
                  {...rest}
                />
              ))}
            </Box>
          )} */}
          {/* {!isLoading && !hiddenPagination && (
            <Stack alignItems="flex-end" mt={2} pr={2}>
              <Pagination data={data} />
            </Stack>
          )} */}
        </>
      )}
    </>
  );
}
