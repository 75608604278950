import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setLogin, setLogout, setInitialize } from "src/redux/slices/settings";
import * as api from "src/services";
import { Decode, Encode } from "src/utils/EncodeDecode";
// utils
import { setSession } from "src/utils/jwt";
// ----------------------------------------------------------------------
const defaultProvider = {
  user: null,
  loading: false,
  setUser: () => null,
  login: () => Promise.resolve(),
  logout: () => {},
};
export const AuthContext = createContext(defaultProvider);

function AuthProvider({ ...props }) {
  const { children } = props;
  // function homeRoute(role) {
  //   if (role == "admin") {
  //     navigate("/");
  //   } else {
  //     navigate("/slides");
  //   }
  // }
  const [user, setUser] = useState(defaultProvider.user);
  const [btnLoading, setBtnLoading] = useState(defaultProvider.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(api.login, {
    onSuccess: (data) => {
      setBtnLoading(isLoading);
      window.localStorage.setItem("token", data.data.access_token);
      toast.success(`${data.message}`);
      dispatch(setLogin(data.data));
      let ar = [];
      ar.push(data.data.userData);
      let encoded = Encode({ data: ar });
      localStorage.setItem("user", encoded);
      setUser(data.data.userData);
      if (data.data.userData.role.value !== "admin") {
        navigate(`/${data.data.userData.role.options[0].value}`);
      } else {
        navigate("/");
      }
      // homeRoute(data.data.userData.role.value);
    },
    onError: (err) => {
      setBtnLoading(false);

      toast.error(`${err.response.data.message}`);
    },
  });

  useEffect(() => {
    dispatch(setInitialize());
    const initialize = async () => {
      try {
        const token = window.localStorage.getItem("token");
        const myuser = localStorage.getItem("user");
        if (token && myuser) {
          let decodeuser = Decode(myuser);
          const userData = decodeuser.data[0];
          setUser(userData);
          setSession(token);
        } else {
          localStorage.removeItem("token");
          dispatch(setLogout(null));
          setUser(null);
          navigate("/auth/login");
        }
      } catch (err) {
        dispatch(setLogout(null));
        navigate("/auth/login");
        setUser(null);
      }
    };

    initialize();
  }, []);
  function handleLogin(data) {
    setBtnLoading(true);
    mutate({ ...data });
  }
  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    dispatch(setLogout());

    navigate("/auth/login");
  }
  const values = {
    login: handleLogin,
    logout: handleLogout,
    user: user,
    loading: btnLoading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export { AuthProvider };
