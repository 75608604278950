import { AbilityBuilder, createMongoAbility } from "@casl/ability";

export const defineAbilitiesForRole = (role, options, subject) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);
  if (options?.length > 0 && !!role) {
    can("read", [...options]);
  } else {
    can(["read", "create", "update", "delete"], subject);
  }
  return build();
};
