// material
import { paramCase } from "change-case";
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Rating,
  Switch,
  Tooltip,
  Link,
} from "@mui/material";
// redux
import { fCurrency } from "src/utils/formatNumber";
import { fDateShort } from "src/utils/formatTime";
// components
import { Label } from "src/components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import * as api from "src/services";
import { ApiUrl } from "src/services/apiUrls";
const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  minWidth: 50,
  objectFit: "cover",
  background: theme.palette.background.default,
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function ProductRow({
  isLoading,
  row,
  handleClickOpen,
  mutate,
  serialNumber,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { data: locationData } = useQuery(["locationData"], () =>
    api.getTrueLocation("")
  );
  const matchedLocation = locationData?.data?.filter((item) =>
    row?.location.includes(item._id)
  );
  return (
    <TableRow hover key={Math.random()}>
      <TableCell>{serialNumber}</TableCell>
      <TableCell component="th" padding="none">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: 300,
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width={44}
              height={44}
              sx={{ borderRadius: 1, margin: (theme) => theme.spacing(0, 1) }}
            />
          ) : row?.image ? (
            <ThumbImgStyle
              alt={row?.name}
              src={`${ApiUrl.IMAGE_BASE_URL}${row?.image}`}
            />
          ) : (
            <Avatar>{row?.name.slice(0, 1)}</Avatar>
          )}
          <Typography variant="subtitle2" noWrap>
            {isLoading ? (
              <Skeleton variant="text" width={120} sx={{ ml: 1 }} />
            ) : (
              row?.name
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          fCurrency(row?.price || row?.price)
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Switch
            {...label}
            defaultChecked={row.is_active}
            onChange={() => {
              mutate({
                id: row._id,
              });
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          matchedLocation
            ?.slice(0, 7)
            .map(
              (item, index) =>
                `${item?.name}${
                  index === matchedLocation?.slice(0, 7).length - 1
                    ? matchedLocation.length <
                      matchedLocation?.slice(0, 7).length - 1
                      ? `...`
                      : ``
                    : `, `
                }`
            )
        )}
      </TableCell>
      <TableCell align="right">
        {isLoading ? (
          <Stack direction="row" justifyContent="flex-end">
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton variant="circular" width={34} height={34} />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <Link
              target="_blank"
              href={`https://nextstore.vercel.com/product/${paramCase(
                row?.name
              )}`}
            >
              {/* <IconButton>
                <RemoveRedEyeIcon />
              </IconButton> */}
            </Link>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(`/products/${row?.slug}`)}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
