import React from "react";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
// components
import EditIcon from "@mui/icons-material/Edit";
import { Label } from "src/components";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "src/utils/formatTime";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "cover",
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
}));
export default function RoleRow({ isLoading, row, serialNumber }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <TableRow hover key={serialNumber}>
      <TableCell>{serialNumber}</TableCell>
      <TableCell align="center" sx={{ textTransform: "capitalize" }}>
        {isLoading ? <Skeleton variant="text" /> : row?.name}
      </TableCell>

      <TableCell align="center">
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={row?.is_active ? "success" : "error"}
          >
            {row.is_active ? "active" : "in-active"}
          </Label>
        )}
      </TableCell>

      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end">
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
          ) : (
            <>
              <Tooltip title="Edit" onClick={() => navigate(`${row._id}`)}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
