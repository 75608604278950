import React from "react";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
// components
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Label } from "src/components";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "src/utils/formatTime";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "cover",
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
export default function ConsultationRow({
  isLoading,
  row,
  isUser,
  productList,
  serialNumber,
  userList,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  console.log(row);
  return (
    <TableRow hover key={Math.random()}>
      <TableCell component="th" scope="row" padding="none">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width={50}
            height={50}
            sx={{ borderRadius: 1 }}
          />
        ) : (
          `${serialNumber}`
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <> {row?.customer?.full_name}</>
        )}
      </TableCell>

      <TableCell>
        {isLoading ? <Skeleton variant="text" /> : `${row?.product?.name}`}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : row?.consultation ? (
          row?.consultation?.date
        ) : (
          ""
        )}
      </TableCell>

      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={row?.is_consulted ? "success" : "error"}
          >
            {row?.is_consulted ? "Consulted" : "Not Consulted"}
          </Label>
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={row?.is_active ? "success" : "error"}
          >
            {row?.is_active ? "Active" : "in-Active"}
          </Label>
        )}
      </TableCell>
      {/* <TableCell>
        {isLoading ? <Skeleton variant="text" /> : row?.order_type}
      </TableCell> */}
      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end">
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
          ) : (
            <Tooltip title="Preview">
              <IconButton onClick={() => navigate(`/consultation/${row._id}`)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
