import React, { useState } from "react";
// material
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ApiUrl } from "src/services/apiUrls";
import { useQuery } from "react-query";
import * as api from "src/services";
const Tablehead = [
  "Product Name",
  "City",
  "size",
  "House Width",
  "Lot Width",
  "price",
];
const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: "cover",
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
}));
export default function ItemsTable({
  data,
  isLoading,
  currency,
  locationList,
}) {
  const { t } = useTranslation("common");
  // const [location, setLocation] = useState([]);
  // console.log(locationList);
  const { data: locationData } = useQuery(["locationData"], () =>
    api.getTrueLocation("")
  );

  return (
    <TableContainer>
      <Table sx={{ minWidth: 800, overflow: "auto" }}>
        <TableHead>
          <TableRow>
            {Tablehead.map((headCell, i) => (
              <TableCell key={`head-${i}`}>{t(headCell)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, i) => {
            return (
              <TableRow>
                <TableCell>
                  {row ? (
                    <Box
                      sx={{
                        py: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ThumbImgStyle
                        alt={row?.name}
                        src={`${ApiUrl.IMAGE_BASE_URL}${row?.image}`}
                      />
                      <Typography variant="subtitle2" noWrap>
                        {row?.name}
                      </Typography>
                    </Box>
                  ) : (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Skeleton variant="rect" width={64} height={64} />
                      <Skeleton variant="text" width={100} />
                    </Stack>
                  )}
                </TableCell>
                <TableCell>
                  {row ? (
                    row.location ? (
                      locationData?.data
                        ?.filter((item) => row.location?.includes(item._id))
                        .map(
                          (item, index) =>
                            `${item?.name}${
                              index === row.location?.length - 1 ? `` : `, `
                            }`
                        )
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </TableCell>

                <TableCell>
                  {row ? row?.size : <Skeleton variant="text" width={100} />}
                </TableCell>
                <TableCell>
                  {row ? (
                    ` ${row.house_width}`
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </TableCell>
                <TableCell>
                  {row ? (
                    row?.lot_width
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </TableCell>
                <TableCell>
                  {row ? row?.price : <Skeleton variant="text" width={100} />}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
