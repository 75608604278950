import http from "./http";
import { ApiUrl } from "./apiUrls";
export const getProducts = async (page, search) => {
  const { data: response } = await http.get(
    `/admin/products?search=${search}&page=${page}`
  );
  return response;
};
export const getFeaturedProducts = async () => {
  const { data: response } = await http.get("/admin/products/featured");
  return response;
};

export const getProductDetails = async (id) => {
  const { data: response } = await http.get(
    `${ApiUrl.GET_PRODUCT_PROFILE_URL}${id}`
  );
  return response;
};

export const newProduct = async (payload) => {
  const { data: response } = await http.post(
    `${ApiUrl.ADD_PRODUCT_URL}`,
    payload.formData
  );
  return response;
};

export const getAllCategories = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_CATEGORY_URL}&search=${search}`
  );
  return data;
};
export const getUserList = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_USER_LIST_URL}?search=${search}`
  );
  return data;
};
export const getRolesList = async (type) => {
  const { data } = await http.get(`${ApiUrl.ROLE_LIST_URL}?type=${type}`);
  return data;
};
export const getAllRolesList = async () => {
  const { data } = await http.get(`${ApiUrl.ALL_ROLE_LIST_URL}`);
  return data;
};

export const getAllProductList = async (search) => {
  const { data } = await http.get(`${ApiUrl.GET_PRODUCT_URL}?search=${search}`);
  return data;
};

export const login = async (payload) => {
  const { data } = await http.post(`${ApiUrl.LOGIN_URL}`, payload);
  return data;
};
export const register = async (payload) => {
  const { data } = await http.post(`${ApiUrl.REGISTER_URL}`, payload);
  return data;
};

export const updateProductStatus = async ({ id, ...payload }) => {
  const { data: response } = await http.put(
    `${ApiUrl.UPDATE_PRODUCT_STATUS_URL}${id}`,
    payload
  );
  return response;
};
export const updateProduct = async ({ id, ...payload }) => {
  // console.log(payload.formData, id);
  const { data: response } = await http.put(
    `${ApiUrl.UPDATE_PRODUCT_URL}${id}`,
    payload.formData
  );
  return response;
};
export const deleteProduct = async (id) => {
  const { data: response } = await http.delete(`/admin/products/${id}`);
  return response;
};
export const getUsers = async (page, search) => {
  const { data: response } = await http.get(
    `/admin/users?search=${search}&page=${page}`
  );
  return response;
};
export const getUser = async (id) => {
  const { data: response } = await http.get(`/admin/users/${id}`);
  return response;
};
export const userStatus = async ({ id, ...payload }) => {
  const { data: response } = await http.put(`/admin/users/${id}`, payload);
  return response;
};
export const userDelete = async (id) => {
  const { data: response } = await http.delete(`/admin/users/${id}`);
  return response;
};
export const getCategories = async (search) => {
  const { data } = await http.get(
    // `/admin/categories?search=${search}&page=${page}`
    `${ApiUrl.GET_ALL_CATEGORY_URL}?search=${search}`
  );
  return data;
};
export const getCategory = async (id) => {
  const { data } = await http.get(`${ApiUrl.GET_CATEGORY_PROFILE}${id}`);
  return data;
};

export const deleteCategory = async (id) => {
  const { data } = await http.delete(`/admin/categories/${id}`);
  return data;
};
export const addCategory = async (payload) => {
  console.log(payload);
  const { data } = await http.post(
    `${ApiUrl.ADD_CATEGORY_URL}`,
    payload.formData
  );
  return data;
};
export const updateCategory = async ({ id, ...payload }) => {
  // console.log(payload);
  const { data } = await http.put(
    `${ApiUrl.EDIT_CATEGORY_URL}/${id}`,
    payload.formData
  );
  return data;
};

export const getSubCategories = async (page, search) => {
  const { data } = await http.get(
    `/admin/categories/sub-categories?search=${search}&page=${page}`
  );
  return data;
};
export const getSubCategory = async (id) => {
  const { data } = await http.get(`/admin/categories/sub-categories/${id}`);
  return data;
};
export const getAllSubCategories = async (id) => {
  const { data } = await http.get(
    `/admin/categories/sub-categories/all-categories`
  );
  return data;
};
export const deleteSubCategory = async (id) => {
  const { data } = await http.delete(`/admin/categories/sub-categories/${id}`);
  return data;
};
export const addSubCategory = async (payload) => {
  const { data } = await http.post(`/admin/categories/sub-categories`, payload);
  return data;
};
export const updateSubCategory = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `/admin/categories/sub-categories/${id}`,
    payload
  );
  return data;
};

export const addRoles = async ({ ...payload }) => {
  const { data } = await http.post(`${ApiUrl.ADD_ROLE_URL}`, payload);
  return data;
};

export const updateRoles = async ({ id, ...payload }) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_ROLE_URL}${id}`, payload);
  return data;
};

export const getAllRoleOptions = async () => {
  const { data } = await http.get(`${ApiUrl.ROLE_OPTION_URL}`);
  return data;
};

export const getRoleProfile = async (id) => {
  const { data } = await http.get(`${ApiUrl.ROLE_PROFILE_URL}${id}`);
  return data;
};
export const getUserProfile = async (id) => {
  const { data } = await http.get(`${ApiUrl.USER_PROFILE_URL}${id}`);
  return data;
};

export const addRoleOtions = async (payload) => {
  const { data } = await http.post(`${ApiUrl.ADD_ROLE_OPTION_URL}`, payload);
  return data;
};

export const updateRoleOption = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `${ApiUrl.UPDATE_ROLE_OPTION_URL}${id}`,
    payload
  );
  return data;
};

export const addLocation = async (payload) => {
  const { data } = await http.post(`${ApiUrl.ADD_LOCATION_URL}`, payload);
  return data;
};
export const getAddOns = async (search) => {
  const { data } = await http.get(`${ApiUrl.GET_ADD_ONS_URL}?search=${search}`);
  return data;
};
export const addAddOns = async (payload) => {
  const { data } = await http.post(`${ApiUrl.ADD_ADD_ONS_URL}`, payload);
  return data;
};
export const updateAddOns = async ({ id, ...payload }) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_ADD_ONS_URL}${id}`, payload);
  return data;
};
export const getDocumentCategory = async () => {
  const { data } = await http.get(`${ApiUrl.GET_DOCUMENT_CATEGORY}`);
  return data;
};
export const getDocumentetails = async (active) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_DOCUMENTS_URL}${typeof active === "boolean" ? `?is_active=${active}` : ""
    }`
  );
  return data;
};
export const addDocumentCategory = async (payload) => {
  // console.log(payload, "addDocumentCategory");
  const { data } = await http.post(`${ApiUrl.ADD_DOCUMENT_CATEGORY}`, payload);
  return data;
};
export const assignDocumentToCustomer = async ({ id, ...payload }) => {
  // console.log(payload, "addDocumentCategory");
  const { data } = await http.put(
    `${ApiUrl.ASSIGN_DOCUMENT_URL}${id}`,
    payload
  );
  return data;
};
export const addDocument = async (payload) => {
  console.log(payload, "addDocumentCategory");
  const { data } = await http.post(`${ApiUrl.ADD_DOCUMENTS_URL}`, payload);
  return data;
};
export const updateDocumentCategory = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `${ApiUrl.UPDATE_DOCUMENT_CATEGORY}${id}`,
    payload
  );
  return data;
};
export const updateDocument = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `${ApiUrl.UPDATE_DOCUMENTS_URL}${id}`,
    payload
  );
  return data;
};
export const getDocumentProfile = async (id) => {
  const { data } = await http.get(`${ApiUrl.GET_DOCUMENT_PROFILE_URL}${id}`);
  return data;
};

export const getActiveDocumentCategory = async () => {
  const { data } = await http.get(`${ApiUrl.GET_ACTIVE_DOCUMENT_CATEGORY}`);
  return data;
};
export const updateLocation = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `${ApiUrl.UPDATE_LOCATION_URL}${id}`,
    payload
  );
  return data;
};

export const getAllLocation = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_LOCATION_URL}?search=${search}`
  );
  return data;
};
export const getTrueLocation = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_TRUE_LOCATION_URL}&search=${search}`
  );
  return data;
};

export const addBanner = async (payload) => {
  // console.log(payload,"addBanner");
  const { data } = await http.post(
    `${ApiUrl.ADD_BANNER_URL}`,
    payload.formData
  );
  return data;
};

export const updateBanner = async ({ id, ...payload }) => {
  console.log(id, "updateBanner");
  const { data } = await http.put(
    `${ApiUrl.UPDATE_BANNER_URL}${id}`,
    payload.formData
  );
  return data;
};

export const getBanners = async () => {
  const { data } = await http.get(`${ApiUrl.GET_BANNERS_URL}`);
  return data;
};

export const getAllConsultations = async (active) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_CONSULTATIONS_URL}${typeof active === "boolean" ? `?is_active=${active}` : ""
    }`
  );
  return data;
};

export const getSingleConsultation = async (id) => {
  const { data } = await http.get(
    `${ApiUrl.GET_CONSULTATION_PROFILE_URL}${id}`
  );
  return data;
};
export const getAllCustomerList = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_CUSTOMER_URL}?search=${search}`
  );
  return data;
};

export const getOrders = async () => {
  const { data } = await http.get(`${ApiUrl.GET_ORDERS_URL}`);
  return data;
};
export const getSingleOrder = async (id) => {
  const { data } = await http.get(`${ApiUrl.GET_ORDER_PROFILE_URL}${id}`);
  return data;
};
export const deleteOrder = async (id) => {
  const { data } = await http.delete(`/admin/orders/${id}`);
  return data;
};
export const updateOrderStatus = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/orders/${id}`, payload);
  return data;
};
export const dashboardData = async () => {
  const { data } = await http.get("/admin/dashboard");
  return data;
};

export const getProfile = async () => {
  const { data } = await http.get("/admin/profile");
  return data;
};
export const updateProfile = async (payload) => {
  const { data } = await http.put("/admin/update-profile", payload);
  return data;
};

export const deteleFiles = async (payload) => {
  const { data } = await http.post("/admin/delete", payload);
  return data;
};
export const singleDeleteFile = async ({ id, type, ...payload }) => {
  const { data } = await http.post(
    `product/delete/${id}?type=${type}`,
    payload
  );
  return data;
};
export const deleteUploadedFile = async (id) => {
  const { data } = await http.delete(
    `${ApiUrl.DELETE_UPLOAD_DOCUMENT_URL}${id}`
  );
  return data;
};
export const selectCurrency = async (payload) => {
  const { data } = await http.put("admin/users/select-currency", payload);
  return data;
};
export const changePassword = async (payload) => {
  const { data } = await http.put(ApiUrl.CHANGE_PASSWORD_URL, payload);
  return data;
};
export const forgetPassword = async (payload) => {
  const { data } = await http.post("/admin/auth/forget-password", payload);
  return data;
};
export const resetPassword = async ({ newPassword, token }) => {
  const { data } = await http.post("/admin/auth/reset-password", {
    newPassword: newPassword,
    token: token,
  });
  return data;
};
export const getNotification = async (page) => {
  const { data } = await http.get(`/admin/notifications?page=${page}`, {});
  return data;
};
export const getUserNotification = async () => {
  const { data } = await http.get(`/admin/notifications/user?page=${1}`);
  return data;
};
export const addUserNotification = async (payload) => {
  const { data } = await http.post(`/admin/notifications/user`, payload);
  return data;
};
export const editUserNotification = async (payload) => {
  const { data } = await http.put(`/admin/notifications/user`, payload);
  return data;
};
export const deleteUserNotification = async (id) => {
  const { data } = await http.delete(`/admin/notifications/user?id=${id}`);
  return data;
};
export const getNewsletter = async (page) => {
  const { data } = await http.get(`/admin/newsletter?page=${page}`);
  return data;
};
export const getRoles = async () => {
  const { data: response } = await http.get(`/admin/roles`);
  return response;
};
// export const addRole = async (payload) => {
//   const { data } = await http.post("/admin/roles", payload);
//   return data;
// };
export const deleteRole = async (id) => {
  const { data } = await http.delete(`/admin/roles/${id}`);
  return data;
};

export const addUser = async (payload) => {
  const { data } = await http.post(`${ApiUrl.ADD_USER_URL}`, payload);
  return data;
};

export const updateUser = async ({ id, ...payload }) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_USER_URL}${id}`, payload);
  return data;
};

export const getPrimarySlider = async () => {
  const { data: response } = await http.get(`/slides/get`);
  return response;
};
export const deletePrimarySlide = async (id) => {
  const { data: response } = await http.delete(`slides/delete/${id}`);
  return response;
};

export const createPrimarySlider = async (payload) => {
  const { data: response } = await http.post(`/slides/add`, payload.formData);
  return response;
};

export const getPrimarySlide = async (id) => {
  const { data } = await http.get(`slides/profile/${id}`);
  return data;
};

export const updatePrimarySlider = async ({ id, ...payload }) => {
  // console.log(id,payload,"updateSlide")
  const { data } = await http.put(`slides/edit/${id}`, payload.formData);
  return data;
};
export const deletePrimarySlider = async (id) => {
  const { data } = await http.delete(`/admin/home-slider/${id}`);
  return data;
};

export const getHomeBanners = async () => {
  const { data } = await http.get(`/admin/home-banners`);
  return data;
};
export const updateHomeBanners = async (payload) => {
  const { data } = await http.post(`/admin/home-banners`, payload);
  return data;
};

// brands
export const getBrands = async (page, search) => {
  const { data } = await http.get(
    `/admin/brands?search=${search}&page=${page}`
  );
  return data;
};
export const getBrand = async (id) => {
  const { data } = await http.get(`/admin/brands/${id}`);
  return data;
};
export const createInvoice = async (payload) => {
  const { data } = await http.post(ApiUrl.INVOICE_ADD_URL, payload);
  return data;
};
export const getInvoiceProfile = async (id) => {
  const { data } = await http.get(
    `${ApiUrl.INVOICE_PROFILE_URL}${id}`,
    payload
  );
  return data;
};
export const getAllBrands = async (id) => {
  const { data } = await http.get(`/admin/brands/all-brands`);
  return data;
};
export const deleteBrand = async (id) => {
  const { data } = await http.delete(`/admin/brands/${id}`);
  return data;
};
export const addBrand = async (payload) => {
  const { data } = await http.post(`/admin/brands`, payload);
  return data;
};
export const updateBrand = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/brands/${id}`, payload);
  return data;
};
// blog
export const getBlogCategories = async (page, search) => {
  const { data } = await http.get(
    `/blogs/categories?search=${search}&page=${page}`
  );
  return data;
};
export const getBlogCategory = async (id) => {
  const { data } = await http.get(`/blogs/categories/${id}`);
  return data;
};
export const getAllBlogCategories = async (id) => {
  const { data } = await http.get(`/blogs/categories`);
  return data;
};
export const deleteBlogCategory = async (id) => {
  const { data } = await http.delete(`/blogs/categories/${id}`);
  return data;
};
export const addBlogCategory = async (payload) => {
  const { data } = await http.post(`/blogs/categories`, payload);
  return data;
};
export const updateBlogCategory = async ({ id, ...payload }) => {
  const { data } = await http.put(`/blogs/categories/${id}`, payload);
  return data;
};
export const uploadDocument = async (payload) => {
  console.log(payload, "PAYLOAD");
  const { data } = await http.post(
    `${ApiUrl.UPLOAD_DOCUMENT_URL}`,
    payload.formData
  );
  return data;
};

export const updateConsultedStatus = async (id) => {
  console.log(id, "consultId");
  const { data } = await http.put(
    `${ApiUrl.UPDATE_CONSULTATION_STATUS_URL}${id}`
  );
  return data;
};
export const updateConvertedToJobStatus = async (id) => {
  const { data } = await http.put(
    `${ApiUrl.UPDATE_CONVERTED_TO_JOB_STATUS_URL}${id}`
  );
  return data;
};

export const updateDocumentStatus = async (id) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_DOCUMENT_STATUS_URL}${id}`);
  return data;
};

export const addZone = async (payload) => {
  const { data } = await http.post(`${ApiUrl.ADD_ZONE_URL}`, payload);
  return data;
};

export const updateZone = async ({ id, ...payload }) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_ZONE_URL}${id}`, payload);
  return data;
};

export const getALLZone = async (search) => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_ZONE_URL}?search=${search}`
  );
  return data;
};

export const getALLTrueZone = async () => {
  const { data } = await http.get(
    `${ApiUrl.GET_ALL_ZONE_URL}?is_active=true&search=`
  );
  return data;
};
export const updateSEOSetting = async (payload) => {
  const { data } = await http.put(`${ApiUrl.UPDATE_SEO_SETTING_URL}`, payload);
  return data;
};
export const getSEOSetting = async () => {
  const { data } = await http.get(`${ApiUrl.GET_SEO_SETTING_URL}`);
  return data;
};
