import CryptoJS from "crypto-js";

const key = "123456";

function Encode(data) {
  let parsedata = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(parsedata, key).toString();
  return encrypted;
}

function Decode(data) {
  const decrypted = CryptoJS.AES.decrypt(data, key);
  const finaldata = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  return finaldata;
}

export { Encode, Decode };
