export { default as MotivationIllustration } from "./illustration_motivation";
export { default as UploadIllustration } from "./illustration_upload";
export { default as SeoIllustration } from "./illustration_seo";
export { default as SeverErrorIllustration } from "./illustration_500";
export { default as MaintenanceIllustration } from "./illustration_maintenance";
export { default as ComingSoonIllustration } from "./illustration_coming_soon";
export { default as PageNotFoundIllustration } from "./illustration_404";
export { default as OrderCompleteIllustration } from "./illustration_order_complete";
export { default as SentIcon } from "./icon_sent";
export { default as DocIcon } from "./icon_doc";
export { default as PlanFreeIcon } from "./icon_plan_free";
export { default as PlanStarterIcon } from "./icon_plan_starter";
export { default as PlanPremiumIcon } from "./icon_plan_premium";
export { default as NoDataIllustration } from "./illustration_no_data";
