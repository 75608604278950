import * as Yup from "yup";
import toast from "react-hot-toast";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import imageCompression from "browser-image-compression";
// material
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  FormHelperText,
  Box,
  Skeleton,
} from "@mui/material";
import { UploadMultiFile, UploadSingleFile } from "src/components";
import * as api from "src/services";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  span: {
    fontSize: 12,
    float: "right",
    fontWeight: 400,
  },
}));

// ----------------------------------------------------------------------

export default function ProductNewForm({ ...props }) {
  const { sid } = useParams();
  const { data, isInitialized } = props;
  const { t } = useTranslation("setting");
  const navigate = useNavigate();
  const [edit, setEdit] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [state, setstate] = useState({
    loading: false,
  });
  console.log(data, "settingData");
  const { mutate, isLoading } = useMutation(
    ["new primary slider"],
    sid ? api.updatePrimarySlider : api.createPrimarySlider,
    {
      onSuccess: (data) => {
        toast.success(
          sid
            ? t(`common:errors.slider-updated`)
            : t(`common:errors.slider-added`)
        );
        navigate("/slides");
      },
      onError: (error) => {
        toast.error(t(`Something went wrong`));
      },
    }
  );
  const NewProductSchema = Yup.object().shape({
    heading: Yup.string().required(t("heading-required")),
    banner: Yup.mixed().required(t("slide-image-is-required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      heading: data?.heading || "",
      primary_button_text: data?.primary_button_text || "",
      primary_button_link: data?.primary_button_link || "",
      secondary_button_text: data?.secondary_button_text || "",
      secondary_button_link: data?.secondary_button_link || "",
      is_active: data?.is_active || false,
      banner: data?.banner || null,
      description: data?.description || "",
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      Object.entries(values).map((item) => {
        formData.append(`${item[0]}`, item[1]);
      });
      try {
        mutate({ formData, ...(sid && { id: sid }) });
      } catch (error) {
        console.error(error);
      }
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDrop = async (acceptedFiles) => {
    setEdit(false);
    setImageLoading(true);
    // const compressedFile = acceptedFiles[0];
    // maxSizeMB: 1,

    // const compressedFile = await imageCompression(acceptedFiles[0], {
    //   // maxSizeMB: 1,
    // });
    // const myFile = new File([compressedFile], compressedFile.name, {
    //   type: compressedFile.type,
    // });
    if (acceptedFiles[0]) {
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setImageLoading(false);
      setFieldValue("banner", acceptedFiles[0]);
    }
  };
  console.log(values);
  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("heading")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          {...getFieldProps("heading")}
                          error={Boolean(touched.heading && errors.heading)}
                          helperText={touched.heading && errors.heading}
                        />
                      </>
                    )}
                  </div>

                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("primary-button-text")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        {...getFieldProps("primary_button_text")}
                        error={Boolean(
                          touched.btnPrimaryText && errors.btnPrimaryText
                        )}
                        helperText={
                          touched.btnPrimaryText && errors.btnPrimaryText
                        }
                      />
                    )}
                  </div>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("primary-button-link")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        {...getFieldProps("primary_button_link")}
                        error={Boolean(
                          touched.btnPrimaryLink && errors.btnPrimaryLink
                        )}
                        helperText={
                          touched.btnPrimaryLink && errors.btnPrimaryLink
                        }
                      />
                    )}
                  </div>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("secondary-button-text")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        {...getFieldProps("secondary_button_text")}
                        error={Boolean(
                          touched.btnSecondaryText && errors.btnSecondaryText
                        )}
                        helperText={
                          touched.btnSecondaryText && errors.btnSecondaryText
                        }
                      />
                    )}
                  </div>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("secondary-button-link")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        {...getFieldProps("secondary_button_link")}
                        error={Boolean(
                          touched.btnSecondaryLink && errors.btnSecondaryLink
                        )}
                        helperText={
                          touched.btnSecondaryLink && errors.btnSecondaryLink
                        }
                      />
                    )}
                  </div>
                  <div>
                    {isInitialized ? (
                      <Skeleton variant="text" width={140} />
                    ) : (
                      <LabelStyle>{t("description")}</LabelStyle>
                    )}
                    {isInitialized ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={56}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        multiline
                        rows={9}
                        sx={{ pt: 1.4 }}
                        {...getFieldProps("description")}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    )}
                  </div>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <div>
                      {isInitialized ? (
                        <Skeleton variant="text" width={"100%"} height={56} />
                      ) : (
                        <LabelStyle>
                          {t("banner")} <span>1920 * 768</span>
                        </LabelStyle>
                      )}
                      {isInitialized ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={56}
                        />
                      ) : (
                        <>
                          <UploadSingleFile
                            file={values.banner}
                            isEdit={edit}
                            onDrop={handleDrop}
                            error={Boolean(touched.banner && errors.banner)}
                            category
                            accept="image/*"
                            loading={imageLoading}
                          />
                          {touched.banner && errors.banner && (
                            <FormHelperText error sx={{ px: 2, mx: 0 }}>
                              {touched.banner && errors.banner}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      {isInitialized ? (
                        <Skeleton variant="rectangular" width={10} />
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                {...getFieldProps("is_active")}
                                checked={values.is_active}
                                setFieldValue={setFieldValue}
                              />
                            }
                            label={values.is_active ? "Enabled" : "Disabled"}
                          />
                        </FormGroup>
                      )}
                    </div>
                  </Stack>
                </Card>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  loading={isLoading || state.loading}
                >
                  {data?._id ? t("Update Slide") : t("Create Slide")}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
