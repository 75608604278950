import React from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import { fDateShort } from "src/utils/formatTime";
import { ar, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { setCategoryId } from "src/redux/slices/settings";
import { useDispatch } from "react-redux";
import Label from "src/components/label";
import { useTheme } from "@emotion/react";

const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  minWidth: 50,
  objectFit: "cover",
  background: theme.palette.background.default,
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
export default function CategoryRow({
  isLoading,
  row,

  serialNumber,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <TableRow hover key={Math.random()}>
      <TableCell>
        {isLoading ? <Skeleton variant="text" /> : serialNumber}
      </TableCell>
      <TableCell>
        {isLoading ? <Skeleton variant="text" /> : row.name}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filledssss"}
            color={row?.is_active ? "success" : "error"}
          >
            {row.is_active ? "Active" : "in-Active"}
          </Label>
        )}
      </TableCell>

      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end">
          {isLoading ? (
            <>
              <Skeleton
                variant="circular"
                width={34}
                height={34}
                sx={{ mr: 1 }}
              />
              <Skeleton variant="circular" width={34} height={34} />
            </>
          ) : (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    navigate(`/categories/${row?._id}`);
                    // setCurrentCategory(row);
                    // dispatch(setCategoryId(row._id))
                    // getBoolValues(true);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete">
                <IconButton onClick={handleClickOpen(row._id)}>
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip> */}
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
