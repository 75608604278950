import PropTypes from "prop-types";
// material
import { TableRow, TableCell, TableHead } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

ProductListHead.propTypes = {
  headData: PropTypes.array,
};

export default function ProductListHead({ headData }) {
  const mode = useSelector((state) => state.settings.mode);
  const { t } = useTranslation("common");
  return (
    <TableHead>
      <TableRow
        sx={{
          background: (theme) => theme.palette.primary.main,
        }}
      >
        {headData.map((headCell) => (
          <TableCell
            key={Math.random()}
            align={
              headCell.alignRight
                ? "right"
                : headCell.alignCenter
                ? "center"
                : "left"
            }
            sx={{
              color: (mode === "dark" && "black") || "white",
              bgcolor: "transparent",
            }}
          >
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
