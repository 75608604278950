import { Navigate, useRoutes } from "react-router-dom";
import { Suspense, createContext, lazy } from "react";

// layouts
import DashboardLayout from "src/layouts/dashboard";
import AuthLayout from "src/layouts/auth";
import LoadingScreen from "src/components/loadingScreen";
import AclGuard from "src/acl/AclGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: "fixed",
          }}
        />
      }
    >
      <AclGuard aclAbilities={props.acl}>
        <Component {...props} />
      </AclGuard>
    </Suspense>
  );
};

export default function asRouter() {
  return useRoutes([
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <DLayout acl={{ subject: "sidebar" }} />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        {
          path: "dashboard",
          element: <LandingPage acl={{ subject: "dashboard" }} />,
        },
        { path: "orders", element: <AllOrder acl={{ subject: "orders" }} /> },
        {
          path: "orders/:id",
          element: <OrderDetail acl={{ subject: "order_edit" }} />,
        },
        {
          path: "jobs",
          element: <Jobs acl={{ subject: "jobs" }} />,
        },
        {
          path: "jobs/:jid",
          element: <AddEditJobs acl={{ subject: "jobs" }} />,
        },
        {
          path: "consultation",
          element: <Consultation acl={{ subject: "consultation" }} />,
        },
        {
          path: "consultation/:id",
          element: (
            <ConsultationDetails acl={{ subject: "consultation_edit" }} />
          ),
        },
        {
          path: "products",
          element: <Products acl={{ subject: "product" }} />,
        },
        {
          path: "products/add",
          element: <AddProduct acl={{ subject: "product" }} />,
        },
        {
          path: "products/:pid",
          element: <ProductUpdate acl={{ subject: "product" }} />,
        },
        {
          path: "categories",
          element: <Categories acl={{ subject: "categories" }} />,
        },
        {
          path: "categories/:cid",
          element: <AddCategory acl={{ subject: "categories" }} />,
        },
        {
          path: "categories/add",
          element: <AddCategory acl={{ subject: "categories" }} />,
        },
        {
          path: "slides",
          element: <MainSlides acl={{ subject: "slides" }} />,
        },
        {
          path: "city",
          element: <Locations acl={{ subject: "locations" }} />,
        },
        {
          path: "add_ons",
          element: <AddOns acl={{ subject: "addons" }} />,
        },
        { path: "banner", element: <Banner acl={{ subject: "banner" }} /> },
        {
          path: "slides/add",
          element: <AddPrimarySlider acl={{ subject: "slides" }} />,
        },
        {
          path: "slides/:sid",
          element: <EditPrimarySlider acl={{ subject: "slides" }} />,
        },
        { path: "brands", element: <Brands /> },
        { path: "brands/add", element: <AddBrands /> },
        { path: "brands/:bid", element: <EditBrands /> },
        {
          path: "employees",
          element: <Users acl={{ subject: "employees" }} />,
        },
        {
          path: "user",
          element: <Customer acl={{ subject: "user" }} />,
        },
        {
          path: "employees/:user_id",
          element: <AddEditUser acl={{ subject: "employees" }} />,
        },
        {
          path: "employees/add",
          element: <AddEditUser acl={{ subject: "employees" }} />,
        },
        // { path: "users/:id", element: <UserProfile /> },
        { path: "newsletter", element: <Newsletter /> },
        {
          path: "settings",
          element: <GeneralSettings acl={{ subject: "setting" }} />,
        },
        { path: "role", element: <Role acl={{ subject: "roles" }} /> },
        {
          path: "role/:role_id",
          element: <AddEditRole acl={{ subject: "roles" }} />,
        },
        {
          path: "role-options",
          element: <RoleOptions acl={{ subject: "roles" }} />,
        },
        {
          path: "role/add",
          element: <AddEditRole acl={{ subject: "roles" }} />,
        },
        {
          path: "role-options/add",
          element: <AddEditRoleOptions acl={{ subject: "roles" }} />,
        },
        {
          path: "document-category",
          element: <Document acl={{ subject: "document" }} />,
        },

        {
          path: "add-document",
          element: <AddDocument acl={{ subject: "document" }} />,
        },
        {
          path: "add-document/add",
          element: <AddDocumentAdd acl={{ subject: "document" }} />,
        },
        {
          path: "add-document/:id",
          element: <AddDocumentEdit acl={{ subject: "document" }} />,
        },
        {
          path: "zone",
          element: <Zone acl={{ subject: "zone" }} />,
        },
        {
          path: "seo",
          element: <Seo acl={{ subject: "seo" }} />,
        },
        { path: "blog/blog-categories", element: <BlogCategories /> },
        { path: "blog/blog-categories/add", element: <AddBlog /> },
        { path: "blog/blog-categories/:blid", element: <EditBlog /> },
        { path: "blog/all-blogs", element: <AllBlog /> },
        { path: "blog/all-blogs/add", element: <AddAllBlog /> },
        { path: "blog/all-blogs/:blid", element: <EditAllBlog /> },
      ],
    },
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <Login /> },
        {
          path: "/auth/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/auth/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/auth/register",
          element: <Register />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
const Jobs = Loadable(lazy(() => import("src/pages/job")));
const AddEditJobs = Loadable(lazy(() => import("src/pages/job/addEditJobs")));
const DLayout = Loadable(lazy(() => import("src/layouts/dashboard")));
const AddEditUser = Loadable(lazy(() => import("src/pages/users/AddEditUser")));
const Users = Loadable(lazy(() => import("src/pages/users/index")));
const Customer = Loadable(lazy(() => import("src/pages/customers/index")));
const AddEditRoleOptions = Loadable(
  lazy(() => import("src/pages/roleOptions/addEditOptions"))
);
const AddEditRole = Loadable(lazy(() => import("src/pages/role/addEditRole")));
const AllOrder = Loadable(lazy(() => import("src/pages/orders")));
const OrderDetail = Loadable(
  lazy(() => import("src/pages/orders/orderDetail"))
);
const Role = Loadable(lazy(() => import("src/pages/role/index")));

const Locations = Loadable(lazy(() => import("src/pages/locations/index")));
const Banner = Loadable(lazy(() => import("src/pages/banner/index")));
const RoleOptions = Loadable(lazy(() => import("src/pages/roleOptions/index")));
const Products = Loadable(lazy(() => import("src/pages/products")));
const AddProduct = Loadable(
  lazy(() => import("src/pages/products/addProduct"))
);
const ProductUpdate = Loadable(
  lazy(() => import("src/pages/products/editProduct"))
);
const Categories = Loadable(lazy(() => import("src/pages/categories")));
const AddCategory = Loadable(
  lazy(() => import("src/pages/categories/editCategory"))
);
const Brands = Loadable(lazy(() => import("src/pages/brands")));

// const Users = Loadable(lazy(() => import("src/pages/users")));

const AddBrands = Loadable(lazy(() => import("src/pages/brands/addBrand.js")));
const EditBrands = Loadable(lazy(() => import("src/pages/brands/editBrand")));

const GeneralSettings = Loadable(
  lazy(() => import("src/pages/settings/general"))
);
const AppSettings = Loadable(
  lazy(() => import("src/pages/settings/application"))
);
const AddPrimarySlider = Loadable(
  lazy(() => import("src/pages/slides/homeSlides/addHomeSlide"))
);
const EditPrimarySlider = Loadable(
  lazy(() => import("src/pages/slides/homeSlides/editHomeSlide"))
);
const EditBanners = Loadable(
  lazy(() => import("src/pages/settings/application/homeBanners/editBanners"))
);
// blog
const BlogCategories = Loadable(lazy(() => import("src/pages/blog")));
const AddBlog = Loadable(lazy(() => import("src/pages/blog/addBlog")));
const EditBlog = Loadable(lazy(() => import("src/pages/blog/editBlog")));
const AllBlog = Loadable(lazy(() => import("src/pages/blog/all-blog")));
const MainSlides = Loadable(lazy(() => import("src/pages/slides")));
const AddAllBlog = Loadable(
  lazy(() => import("src/pages/blog/all-blog/addAllBlog"))
);
const EditAllBlog = Loadable(
  lazy(() => import("src/pages/blog/all-blog/editAllBlog"))
);
// Main
const LandingPage = Loadable(lazy(() => import("src/pages")));

// notfound
const NotFound = Loadable(lazy(() => import("src/pages/404")));

// auth
const ForgetPassword = Loadable(
  lazy(() => import("src/pages/auth/forgetPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("src/pages/auth/resetPassword"))
);
const Login = Loadable(lazy(() => import("src/pages/auth/login")));
const Newsletter = Loadable(lazy(() => import("src/pages/newsletter")));
const Register = Loadable(lazy(() => import("src/pages/auth/register")));
const AddOns = Loadable(lazy(() => import("src/pages/addOns")));

// Document-section
const Document = Loadable(
  lazy(() => import("src/pages/documentCategory/index"))
);
const AddDocument = Loadable(lazy(() => import("src/pages/addDocument")));
const AddDocumentAdd = Loadable(
  lazy(() => import("src/pages/addDocument/addDocument"))
);
const AddDocumentEdit = Loadable(
  lazy(() => import("src/pages/addDocument/editDocument"))
);
const Consultation = Loadable(lazy(() => import("src/pages/consultation")));
const ConsultationDetails = Loadable(
  lazy(() => import("src/pages/consultation/consultationDetails"))
);
const Zone = Loadable(lazy(() => import("src/pages/zone")));
const Seo = Loadable(lazy(() => import("src/pages/seo")));
