// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";

import { Label } from "src/components";
import { useNavigate } from "react-router-dom";

import { capitalize } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

export default function UserRow({ isLoading, row, serialNumber, role }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchedRole = role?.find((item) => item._id === row?.role);
  console.log(matchedRole, "ROWEMP");
  return (
    <TableRow hover key={Math.random()}>
      <TableCell style={{ minWidth: 160 }}>
        {isLoading ? <Skeleton variant="text" /> : serialNumber}
      </TableCell>
      <TableCell style={{ minWidth: 160 }}>
        {isLoading ? <Skeleton variant="text" /> : row?.name}
      </TableCell>
      <TableCell style={{ minWidth: 160 }}>
        {isLoading ? <Skeleton variant="text" /> : row?.email}
      </TableCell>

      <TableCell style={{ minWidth: 80 }}>
        {isLoading ? <Skeleton variant="text" /> : row?.phone}
      </TableCell>
      <TableCell style={{ minWidth: 160 }}>
        {isLoading ? <Skeleton variant="text" /> : matchedRole?.name}
      </TableCell>
      <TableCell style={{ minWidth: 40 }}>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={row?.is_active ? "success" : "error"}
          >
            {capitalize(row?.is_active ? "Active" : "in-Active")}
          </Label>
        )}
      </TableCell>

      <TableCell>
        <Stack direction="row" justifyContent="flex-end">
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
          ) : (
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(`/employees/${row?._id}`)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
