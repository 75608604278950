import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  user: {
    // _id: "64fec310cf2973db3953be21",
    // email: "test@nextstore.com",
    // name: "Admin",
    // cover: {
    //   _id: "my-uploads/e9uo6ag0agkcqgt7vafq",
    //   url: "https://res.cloudinary.com/techgater/image/upload/v1704633273/my-uploads/e9uo6ag0agkcqgt7vafq.jpg",
    // },
    // status: "active",
    // iat: 1708492498,
    // exp: 1709097298,
  },
  count: 0,
  currency: "USD",
  symbol: "US$",
  mode: "light",
  cId: null,
  isInitialized: true,
};

const slice = createSlice({
  name: "settings",
  initialState,

  reducers: {
    setLogin(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setLogout(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
    setCurrency(state, action) {
      state.currency = action.payload.prefix;
      state.symbol = action.payload.symbol;
    },
    setInitialize(state) {
      state.isInitialized = true;
    },

    setCount(state) {
      state.count = state.count + 1;
    },
    setThemeMode(state, action) {
      state.mode = action.payload;
    },
    setCategoryId(state, action) {
      state.cId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLogin,
  setLogout,
  setCount,
  setCurrency,
  setInitialize,
  setThemeMode,
  setCategoryId
} = slice.actions;

// ----------------------------------------------------------------------
